<form [formGroup]="contactForm" (ngSubmit)="onSubmit()">
  <div class="commonContactForm">
    <div class="page-title-area">
      <div class="container">
        <div class="page-title-content">
          <h2>{{title}}</h2>
          <ul>
            <li><a routerLink="/">Home</a></li>
          </ul>
        </div>
      </div>
    </div>
    <div class="contact-area pt-4 pb-4">
      <div class="container">
        <div class="section-title text-center mb-4">
          <p>We are all the team experts and specialists of particular branches falls into
            researches and diagnosis, ready to serve you at their best.</p>
        </div>
        <div class="row justify-content-center">
          <div class="col-md-8">
            <div class="row">
              <div class="col-lg-6 col-md-6">
                <div class="form-group">
                  <input type="text" class="form-control" formControlName="name" placeholder="Name*" />
                  <small class="errorField" *ngIf="name.invalid && name.touched">Name is Required <sup>*</sup></small>
                </div>
              </div>
              <div class="col-lg-6 col-md-6">
                <div class="form-group">
                  <input type="email" class="form-control" formControlName="email" placeholder="Email*" />
                  <small class="errorField" *ngIf="email.invalid && email.touched">Email is Required <sup>*</sup></small>
                </div>
              </div>
              <div class="col-lg-6 col-md-6">
                <div class="form-group">
                  <input type="text" class="form-control" formControlName="phone_number" placeholder="Phone No*" />
                  <small class="errorField" *ngIf="phone_number.invalid && phone_number.touched">Phone Number is Required <sup>*</sup></small>
                </div>
              </div>
              <div class="col-lg-6 col-md-6">
                <div class="form-group">
                  <input type="text" class="form-control" formControlName="subject" placeholder="Subject*" />
                  <small class="errorField" *ngIf="subject.invalid && subject.touched">Subject is Required <sup>*</sup></small>
                </div>
              </div>
              <div class="col-lg-12 col-md-12">
                <div class="form-group">
                  <textarea formControlName="message" cols="100" rows="6" class="form-control" placeholder="Message"></textarea>
                </div>
              </div>
              <div class="col-lg-12 col-md-12 text-center">
                <button type="submit" class="btn btn-primary mt-4">Submit</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>

<div *ngIf="successMessage" class="alert alert-success" role="alert">
  {{ successMessage }}
</div>
