<footer class="footer-area pt-5  bg-fffcfb">
    <div class="container">
        <div class="row pt-5 pb-5">
           <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <div class="logo pb-2">
                        <a routerLink="/"><img src="assets/img/logo.png" alt="logo"></a>
                        
                    </div>

                   <p>
                    

Unique Wellness Care Lab is a trusted name in diagnostic services. With a commitment to accuracy and care.

                   </p>
                </div>
            </div>
            <div class="col-lg-1 col-md-1 col-sm-1"></div>
            <div class="col-lg-2 col-md-6 col-sm-6">
                <div class="single-footer-widget pl-5 ml-3">
                    <h3>Useful Links</h3>

                    <ul class="useful-links-list">
                        <li><a routerLink="/about-us">About Us</a></li>
                        <li><a routerLink="/services">Services</a></li>
                        <li><a routerLink="#">Blogs</a></li>
                        <li><a routerLink="/contact">Contact</a></li>
                       
                    </ul>
                </div>
            </div>
            
            <div class="col-lg-2 col-md-2 col-sm-2">
                <div class="single-footer-widget pl-3">
                    <h3>Our Services</h3>

                    <ul class="widget-services-list">
                        <li><a routerLink="/test-list">TEST LIST</a></li>
                        <li><a routerLink="/a-profile">PROFILES</a></li>
                        <li><a routerLink="/book-now">FREE HOME COLLECTION</a></li>
                        <li><a routerLink="/book-now">BOOK NOW</a></li>
                       
                    </ul>
                </div>
            </div>

            <div class="col-lg-4 col-md-4 col-sm-4">
                <div class="single-footer-widget">
                    <h3>Contact Info</h3>

                    <ul class="footer-contact-info">
                        <li><i class="flaticon-facebook-placeholder-for-locate-places-on-maps"></i>
                            Plot No.19,Street NO.5/12, Guru Nanak Dev Colony, Bhalswa Dairy, New Delhi, Delhi 110042</li>
                        <li><i class="flaticon-phone"></i> <a href="tel: +91 8181815299 "> +91 8181815299 </a></li>
                        <li><i class="flaticon-smartphone-call"></i> <a href="tel: +91 8181815299 "> +91 8181815299 </a></li>
                        <li><i class="flaticon-close-envelope"></i> <a href="mailto:info@uniquewellnesscare.com">info&#64;uniquewellnesscare.com</a></li>
                 
                    </ul>
                </div>
            </div>
        </div>

        <div class="copyright-area">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <p><a href="https:www.uniquewellnesscare.com" target="_blank">Unique Wellness care</a> © 2023. All rights reserved.</p>
                </div>

                <div class="col-lg-6 col-md-6 col-sm-6">
                    <ul>
                        <li><a routerLink="/terms-conditions" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Terms & Conditions |</a></li>
                        <li><a routerLink="/privacy-policy" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Privacy Policy |</a></li>
                        <li><a href="https://www.facebook.com/uniquewellnesscare" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                        <li><a href="/https://twitter.com/UNIQUEWELLNESS5" target="_blank"><i class="fab fa-twitter"></i></a></li>
                       
                        

                        <li><a href="https://www.instagram.com/unique_wellness_care/" target="_blank"><i class="fab fa-instagram"></i></a></li>
                       
                       
                    </ul>
                </div>
            </div>
        </div>
    </div>
</footer>
<div class="social">
    <ul>
       <!--<li><a href="mailto:info@uniquewellnesscare.com"  target="_blank" class="em"><i class="fas fa-envelope"></i></a></li>--> 
      <li><a href="https://www.facebook.com/uniquewellnesscare"  target="_blank" class="fb"><i class="fab fa-facebook-f" aria-hidden="true"></i></a></li>
      <li><a href="https://twitter.com/UNIQUEWELLNESS5"  target="_blank" class="tw"><i class="fab fa-twitter" aria-hidden="true"></i></a></li>
      <li><a href="https://www.instagram.com/unique_wellness_care/"  target="_blank" class="gp"><i class="fab fa-instagram" aria-hidden="true"></i></a></li>
      <li><a href="https://www.youtube.com/channel/UCNDO6jEAo_77esReqy0Hshg"  target="_blank" class="yt"><i class="fab fa-youtube" aria-hidden="true"></i></a></li>
      
    </ul>
  </div>
  
 
 
<!--<div class="rtl-content">
    <a href="#" class="rtl-btn">
        <a routerLink="/"><img src="assets/img/shiv.png" alt="logo"></a>
    </a>
    </div>-->

    <a rel="noopener" data-mobile-target="" data-desktop-target="_blank" href="https://api.whatsapp.com/send?phone=+918510025135&text=Thank you for contacting Unique wellness care! Please let us know how we can help you." class="float" target="_blank">
        <i class="fa fa-whatsapp my-float"></i>
        </a>
        <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css">
        <a href="tel:91 8510025135" class="float2">
        <i class="fa fa-phone my-float2"></i>
        </a>
       
<!--<div class="container">
    <div class="row">
        <div class="col-md-12">
            <div class="nabl_cap_certify"> <a href="/cap-accreditation"><img src="assets/img/nbl.jpg" class="shimmer" alt="NABL CAP Certificate" title="NABL CAP Certificate" id="nabl_cap_img" width="245"></a> </div>    
        </div>
    </div>
</div>-->