<div class="navbar-area hide_this">
    <div class="header-top d-none d-lg-block">
        <div class="container">
            <div class="row">
                <div class="col-md-3">
                    <ul class="list-inline m-0 cstmTopMenu">
                        <!--<li class="list-inline-item"> 
                        <a href="/"><i class="fas fa-user"></i> Download Report</a>
                    </li>-->
                        <li class="list-inline-item"> <a
                               href="https://itd-saas01-cl.ondgni.com/UniqueWellness/Design/Default.aspx"
                                target="_blank" class="nav-link"><i class="fas fa-home"></i> Franchise</a></li>

                    </ul>
                </div>
                <div class="col-md-9 text-end">
                    <ul class="list-inline m-0 cstmTopMenu">
                        <li class="list-inline-item">
                            <a href="mailto:info@uniquewellnesscare.com"><i class="fas fa-envelope"></i>
                                info&#64;uniquewellnesscare.com</a>
                        </li>
                        <li class="list-inline-item">
                            <a href="tel:011-71859845"><i class="fas fa-mobile-alt"></i>011-71859845</a>
                        </li>
                        <li class="list-inline-item">
                            <a href="#" data-toggle="modal" data-target="#form"><img src="assets/img/phone-call.svg"
                                    class="svg-color">Reguest A Call Back </a>
                        </li>


                    </ul>
                </div>
                <!-- <div class="col-md-2 text-left"><a class="uwc-l" href="#"><i class="fas fa-mobile-alt"></i> +91 8181815299</a></div>
           <div class="col-md-3 text-left"><a class="uwc-l uwc-2" href="#"><i class="fas fa-envelope"></i> info@uniquewellnesscare.com</a></div>
  
            
            <div class="col-md-7 text-right"><a class="uwc-l"  href="/customer-login"><i class="fas fa-user"></i> Login</a></div>-->

            </div>
        </div>
    </div>
    <div class="zylon-mobile-nav">
        <div class="logo">
            <a href="/"><img src="assets/img/logo.png" class="imr" alt="logo"></a>
        </div>
    </div>

    <div class="zylon-nav">
        <div class="container">
            <nav class="navbar navbar-expand-md navbar-light">

                <a class="navbar-brand" href="/"><img src="assets/img/logo.png" class="im" alt="logo"></a>

                <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                    <ul class="navbar-nav">
                        <li class="nav-item"><a href="/" class="nav-link"><img src="assets/img/nbl.jpg" class="shimmer"
                                    alt="NABL CAP Certificate" title="NABL CAP Certificate" id="nabl_cap_img"
                                    width="245"> </a></li>
                        <li class="nav-item"><a href="/" class="nav-link">Home</a></li>
                        <li class="nav-item">
                            <a href="/about-us">About Us</a>
                        </li>



                        <li class="nav-item d-block d-lg-none"><a href="https://itd-saas01-cl.ondgni.com/UniqueWellness/Design/Default.aspx" target="_blank"
                                class="nav-link">Franchise</a></li>

                        <li class="nav-item"><a href="javascript:void(0)" class="nav-link">Services <i
                                    class="fas fa-chevron-down"></i></a>
                            <ul class="dropdown-menu">
                                <li class="nav-item"><a href="/test-list" class="nav-link">TEST LIST</a></li>
                                <li class="nav-item"><a href="/a-profile" class="nav-link">PROFILES</a></li>
                                <li class="nav-item"><a href="/book-now" class="nav-link">FREE HOME COLLECTION</a></li>



                            </ul>
                        </li>






                        <li class="nav-item"><a href="javascript:void(0)" href="/contact" class="nav-link">Contact Us <i
                                    class="fas fa-chevron-down"></i></a>
                            <ul class="dropdown-menu">
                                <li class="nav-item"><a href="/for-a-dsa" class="nav-link">For a DSA</a></li>

                                <li class="nav-item"><a href="/business-partner" class="nav-link">Business Partner</a>
                                </li>

                                <li class="nav-item"><a href="/any-other-query" class="nav-link">any other query</a>
                                </li>

                                <li class="nav-item"><a href="/feed-back" class="nav-link">Feed Back</a></li>
                            </ul>
                        </li>
                        <li class="nav-item d-block d-lg-none"><a href="tel:91 8510025135 " class="">+91 8510025135</a>
                        </li>
                        <li class="nav-item d-block d-lg-none"> <a href="tel:91 8181815299 " class=""> +91 8181815299
                            </a></li>
                        <li class="nav-item d-block d-lg-none"> <a href="mailto:info@uniquewellnesscare.com"
                                class="">info&#64;uniquewellnesscare.com</a></li>

                    </ul>

                    <div class="others-options">
                        <!-- <div class="languages-list">
                            <select>
                                <option value="1">En</option>
                                <option value="2">Ge</option>
                                <option value="3">Spa</option>
                            </select>
                        </div>-->
                        <a href="/book-now" class="btn btn-secondary">book Now</a>
                    </div>
                </div>
            </nav>
        </div>
    </div>
</div>
<div class="modal fade" id="form" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">

            <div class="modal-body">
                <div class="appointment-form">
                    <!-- Request A Call Back -->
                    <form [formGroup]="contactForm" (ngSubmit)="onSubmit()">
                        <div class="requestACallBack">
                            <h3>{{title}}</h3>

                            <div class="form-group">
                                <input type="text" class="form-control" name="name" formControlName="name"
                                    placeholder="Name*" required>
                                <small class="errorField" *ngIf="name.invalid && name.touched">Name is Required
                                    <sup>*</sup></small>
                            </div>
                            <div class="form-group">
                                <input type="text" class="form-control" name="phone_number"
                                    formControlName="phone_number" placeholder="Phone No*" required>
                                <small class="errorField" *ngIf="phone_number.invalid && phone_number.touched">Phone
                                    Number is Required <sup>*</sup></small>
                            </div>
                            <button type="submit" class="btn btn-primary" [disabled]='!contactForm.valid'>Get A Callback
                                !</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
<app-alert-msg *ngIf="alert"></app-alert-msg>