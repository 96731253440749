<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>D PROFILE</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>D Profile</li>
            </ul>
        </div>
    </div>
</div>
<section class="services-details-area ptb-120">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <img src="assets/img/d.jpg" alt="image">
                
                <div class="services-details">

                    <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                        <div class="panel panel-default">
                            <div class="panel-heading" role="tab" id="headingOne">
                                <h4 class="panel-title">
                                    <a role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseOne"
                                        aria-expanded="true" aria-controls="collapseOne">
                                        COMPLETE BLOOD COUNT (28)
                                    </a>
                                </h4>
                            </div>
                            <div id="collapseOne" class="panel-collapse collapse in" role="tabpanel"
                                aria-labelledby="headingOne">
                                <div class="panel-body">

                                    Hemoglobin (Hb)<br>
                                    Total Leucocytes (WBC) Count<br>
                                    Differential Leukocyte Count(DLC) Neutrophils<br>
                                    Lymphocytes<br>
                                    Eosinophils<br>
                                    Monocytes<br>
                                    Basophils<br>
                                    Erythrocyte (RBC) Count<br>
                                    Packed Cell Volume (PCV)<br>
                                    Mean Cell Volume (MCV)<br>
                                    Mean Cell Haemoglobin (MCH)<br>
                                    Mean Corpuscular Hb Concn. (MCHC)<br>
                                    Red Cell Distribution Width (RDW)<br>
                                    Platelet Count<br>
                                    Mean Platelet Volume (MPV)<br>
                                    PCT<br>
                                    PDW<br>
                                    Nucleated RBC's<br>
                                    ABSOLUTE COUNT<br>
                                    Absolute Lymphocyte Count<br>
                                    Absolute Neutrophil Count<br>
                                    Absolute Eosinophil Count<br>
                                    Absolute Monocyte Count<br>
                                    Myelocytes<br>
                                    Metamyelocytes<br>
                                    Platelet Large Cell Ratio (P-LCR)<br>
                                    P-LCC<br>
                                    RDW-SD<br>
                                    ESR


                                </div>
                            </div>
                        </div>
                        <div class="panel panel-default">
                            <div class="panel-heading" role="tab" id="headingTwo">
                                <h4 class="panel-title">
                                    <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion"
                                        href="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                        LIVER FUNCTION TEST (10)
                                    </a>
                                </h4>
                            </div>
                            <div id="collapseTwo" class="panel-collapse collapse" role="tabpanel"
                                aria-labelledby="headingTwo">
                                <div class="panel-body">
                                    Bilirubin - Total<br>
                                    Bilirubin - Direct<br>
                                    Bilirubin - Indirect<br>
                                    SGOT<br>
                                    SGPT<br>
                                    Alkaline Phosphatase-ALP<br>
                                    Total Protein<br>
                                    Albumin<br>
                                    Globulin<br>
                                    A/G Ratio
                                    
                                </div>
                            </div>
                        </div>
                        <div class="panel panel-default">
                            <div class="panel-heading" role="tab" id="headingThree">
                                <h4 class="panel-title">
                                    <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion"
                                        href="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                        KIDNEY FUNCTION TEST (6)
                                    </a>
                                </h4>
                            </div>
                            <div id="collapseThree" class="panel-collapse collapse" role="tabpanel"
                                aria-labelledby="headingThree">
                                <div class="panel-body">
                                    Blood Urea<br>
                                    Blood Urea Nitrogen-BUN<br>
                                    Creatinine<br>
                                    Uric Acid<br>
                                    Calcium<br>
                                    BUN/SCRE RATIO<br>
                                    
                                    
                                </div>
                            </div>
                        </div>
                        <div class="panel panel-default">
                            <div class="panel-heading" role="tab" id="headingFive">
                                <h4 class="panel-title">
                                    <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion"
                                        href="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                        LIPID PROFILE (7)
                                    </a>
                                </h4>
                            </div>
                            <div id="collapseFive" class="panel-collapse collapse" role="tabpanel"
                                aria-labelledby="headingFive">
                                <div class="panel-body">
                                    Cholesterol-Total<br>
Triglycerides<br>
HDL Cholesterol<br>
LDL Cholesterol<br>
VLDL Cholesterol<br>
CHOL/HDL Ratio<br>
LDL/HDL Ratio<br>

                                    
                                </div>
                            </div>
                        </div>
                        <div class="panel panel-default">
                            <div class="panel-heading" role="tab" id="headingFour">
                                <h4 class="panel-title">
                                    <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion"
                                        href="#collapseFour" aria-expanded="false" aria-controls="collapseThree">
                                        ELECTROLYTES PROFILE (3)
                                    </a>
                                </h4>
                            </div>
                            <div id="collapseFour" class="panel-collapse collapse" role="tabpanel"
                                aria-labelledby="headingFour">
                                <div class="panel-body">
                                    SODIUM<br>
                                    POTASSIUM<br>
                                    CHLORIDE<br>
                                    
                                    
                                </div>
                            </div>
                        </div>
  
                        <div class="panel panel-default">
                            <div class="panel-heading" role="tab" id="headingSix">
                                <h4 class="panel-title">
                                    <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion"
                                        href="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                       
                                        IRON PROFILE (4)

                                    </a>
                                </h4>
                            </div>
                            <div id="collapseSix" class="panel-collapse collapse" role="tabpanel"
                                aria-labelledby="headingSix">
                                <div class="panel-body">
                                    IRON <br>
                                    UIBC<br>
                                    TIBC<br>
                                    % TRANSFERRIN SATURATION<br>
                                    
                                    
                                </div>
                            </div>
                        </div>
                        <div class="panel panel-default">
                            <div class="panel-heading" role="tab" id="headingSeven">
                                <h4 class="panel-title">
                                    <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion"
                                        href="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                                       
                                        THYROID PROFILE (3)

                                    </a>
                                </h4>
                            </div>
                            <div id="collapseSeven" class="panel-collapse collapse" role="tabpanel"
                                aria-labelledby="headingSeven">
                                <div class="panel-body">
                                    T3-Total<br>
                                    T4-Total<br>
                                    TSH-Ultrasensitive<br>
                                    
                                </div>
                            </div>
                        </div>
                        <div class="panel panel-default">
                            <div class="panel-heading" role="tab" id="headingEight">
                                <h4 class="panel-title">
                                    <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion"
                                        href="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                                       
                                        
DIABETIC PROFILE (4)


                                    </a>
                                </h4>
                            </div>
                            <div id="collapseEight" class="panel-collapse collapse" role="tabpanel"
                                aria-labelledby="headingEight">
                                <div class="panel-body">
                                    FASTING BLOOD SUGAR <br>
                                    HBA1C <br>
                                    AVERAGE BLOOD GLUCOSE<br>
                                    URINE SUGAR<br>
                                    
                                </div>
                            </div>
                        </div>
                        <div class="panel panel-default">
                            <div class="panel-heading" role="tab" id="headingNine">
                                <h4 class="panel-title">
                                    <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion"
                                        href="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
                                       
URINE EXAMINATION (25)

                                    </a>
                                </h4>
                            </div>
                            <div id="collapseNine" class="panel-collapse collapse" role="tabpanel"
                                aria-labelledby="headingNine">
                                <div class="panel-body">
                                    Colour<br>
                                    Quantity<br>
                                    Transparency (Appearance) Deposit<br>
                                    Reaction (pH)<br>
                                    Specific gravity<br>
                                    Urine Protein (Albumin)<br>
                                    Urine Glucose (Sugar) Bilirubin<br>
                                    Blood<br>
                                    Red blood cells<br>
                                    Pus cells (WBCs) Epithelial cells CRYSTALS<br>
                                    Calcium Oxalate<br>
                                    Triple Phosphate Uric Acid Crystals<br>
                                    Calcium Carbonate Crystals<br>
                                    Amorphous deposits <br>
                                    Amorphous urates<br>
                                     Amorphous Phosphates<br>
                                    Granular Cast<br>
                                    Hyline Cast<br>
                                    RBC Cast<br>
                                    WBC Cast<br>
                                    Epithelial Cast<br>
                                    Mucus Threads<br>
                                    Bacteria<br>
                                    Yeast cells<br>
                                    Spermatozoa<br>
                                    Other<br>
                                    
                                </div>
                            </div>
                        </div>
                        <div class="panel panel-default">
                            <div class="panel-heading" role="tab" id="headingTwo">
                                <h4 class="panel-title">
                                    <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion"
                                        href="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                        LIVER FUNCTION TEST (10)
                                    </a>
                                </h4>
                            </div>
                            <div id="collapseTwo" class="panel-collapse collapse" role="tabpanel"
                                aria-labelledby="headingTwo">
                                <div class="panel-body">
                                    Bilirubin - Total<br>
                                    Bilirubin - Direct<br>
                                    Bilirubin - Indirect<br>
                                    SGOT<br>
                                    SGPT<br>
                                    Alkaline Phosphatase-ALP<br>
                                    Total Protein<br>
                                    Albumin<br>
                                    Globulin<br>
                                    A/G Ratio
                                    
                                </div>
                            </div>
                        </div>
                        <div class="panel panel-default">
                            <div class="panel-heading" role="tab" id="headingTen">
                                <h4 class="panel-title">
                                    <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion"
                                        href="#collapseTen" aria-expanded="false" aria-controls="collapseTen">
                                        Peripheral Smear Examination (5)
                                    </a>
                                </h4>
                            </div>
                            <div id="collapseTen" class="panel-collapse collapse" role="tabpanel"
                                aria-labelledby="headingTen">
                                <div class="panel-body">
                                    Specimen<br>
                                    RBC<br>
                                    WBC<br>
                                    PLATELET<br>
                                    Hemoparasite<br>
                                    Impression<br>
                                    
                                    
                                </div>
                            </div>
                        </div>
                        <div class="panel panel-default">
                            <div class="panel-heading" role="tab" id="headingEleven">
                                <h4 class="panel-title">
                                    <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion"
                                        href="#collapseEleven" aria-expanded="false" aria-controls="collapseEleven">
                                        VITAMINS PROFILE (2)
                                    </a>
                                </h4>
                            </div>
                            <div id="collapseEleven" class="panel-collapse collapse" role="tabpanel"
                                aria-labelledby="headingEleven">
                                <div class="panel-body">
                                    25 HYDROXY VITAMIN D TOTAL<br>
                                    VITAMIN- B12<br>
                                    
                                    
                                </div>
                            </div>
                        </div>
                        <div class="panel panel-default">
                            <div class="panel-heading" role="tab" id="headingtwelve">
                                <h4 class="panel-title">
                                    <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion"
                                        href="#collapsetwelve" aria-expanded="false" aria-controls="collapsetwelve">
                                        CARDIAC RISK MARKER PROFILE (6)
                                    </a>
                                </h4>
                            </div>
                            <div id="collapsetwelve" class="panel-collapse collapse" role="tabpanel"
                                aria-labelledby="headingtwelve">
                                <div class="panel-body">
                                    APO-A<br>
                                    APO-B<br>
                                    APO A:B RATIO<br>
                                    C REACTIVE PROTEIN <br>
                                    LIPO-PROTEIN<br>
                                    CK-NACK<br>
                                    
                                    
                                </div>
                            </div>
                        </div>
                        <div class="panel panel-default">
                            <div class="panel-heading" role="tab" id="headingthirteen">
                                <h4 class="panel-title">
                                    <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion"
                                        href="#collapsethirteen" aria-expanded="false" aria-controls="collapsethirteen">
                                        PANCREAS PROFILE (2)
                                    </a>
                                </h4>
                            </div>
                            <div id="collapsethirteen" class="panel-collapse collapse" role="tabpanel"
                                aria-labelledby="headingthirteen">
                                <div class="panel-body">
                                    AMYLASE <br>
                                    LIPASE<br>
                                    
                                    
                                    
                                </div>
                            </div>
                        </div>

                        <div class="panel panel-default">
                            <div class="panel-heading" role="tab" id="headingfourteen">
                                <h4 class="panel-title">
                                    <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion"
                                        href="#collapsefourteen" aria-expanded="false" aria-controls="collapsefourteen">
                                        ARTHRITIS PROFILE (2)
                                    </a>
                                </h4>
                            </div>
                            <div id="collapsefourteen" class="panel-collapse collapse" role="tabpanel"
                                aria-labelledby="headingfourteen">
                                <div class="panel-body">
                                    PHOSPHOROUS <br>
                                    MAGNESIUM<br>
                                    
                                    
                                    
                                </div>
                            </div>
                        </div>
                    </div>

                </div>


            </div>


            <div class="col-lg-4 col-md-12">
                <div class="services-sidebar">
                    <div class="services-list">
                        <ul>
                            <li><a routerLink="/a-profile" class="active">A Profile <i
                                        class="flaticon-right-arrow"></i></a></li>
                            <li><a routerLink="/b-profile">B Profile <i class="flaticon-right-arrow"></i></a></li>
                            <li><a routerLink="/c-profile">C Profile <i class="flaticon-right-arrow"></i></a></li>
                            <li><a routerLink="/d-profile">D Profile <i class="flaticon-right-arrow"></i></a></li>

                        </ul>
                    </div>

                    <!-- <div class="services-download-list">
                        <ul>
                            <li><a routerLink="/single-services"><i class="flaticon-pdf"></i> Download Pdf File</a></li>
                            <li><a routerLink="/single-services"><i class="flaticon-txt"></i> Download Txt File</a></li>
                        </ul>
                    </div>-->
                </div>
            </div>
        </div>
    </div>
</section>