import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-looking-for-corporate-tie-up',
  templateUrl: './looking-for-corporate-tie-up.component.html',
  styleUrls: ['./looking-for-corporate-tie-up.component.scss']
})
export class LookingForCorporateTieUpComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
