<div class="row align-items-center">
    <div class="partner-item">
        <div class="single-partner">
            <a href="#" target="_blank"><img src="assets/img/partner-img1.png" alt="image"></a>
        </div>
    </div>

    <div class="partner-item">
        <div class="single-partner">
            <a href="#" target="_blank"><img src="assets/img/partner-img2.png" alt="image"></a>
        </div>
    </div>

    <div class="partner-item">
        <div class="single-partner">
            <a href="#" target="_blank"><img src="assets/img/partner-img3.png" alt="image"></a>
        </div>
    </div>

    <div class="partner-item">
        <div class="single-partner">
            <a href="#" target="_blank"><img src="assets/img/partner-img4.png" alt="image"></a>
        </div>
    </div>

    <div class="partner-item">
        <div class="single-partner">
            <a href="#" target="_blank"><img src="assets/img/partner-img5.png" alt="image"></a>
        </div>
    </div>
</div>