import { Component, OnInit, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import emailjs from '@emailjs/browser';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  @Input() title = 'Get In Touch!';
  contactForm: FormGroup;
  successMessage: string | null = null;
  errorMessage: string | null = null;
  alert: boolean = false;

  constructor(private meta: Meta, private titleService: Title) {
    this.contactForm = new FormGroup({
      name: new FormControl('', Validators.required),
      email: new FormControl('', [Validators.required, Validators.email]),
      phone_number: new FormControl('', [
        Validators.required,
        Validators.pattern('^((\\+91-?)|0)?[0-9]{10}$')
      ]),
      message: new FormControl('')
    });
  }

  ngOnInit(): void {
    // Update title dynamically
    this.titleService.setTitle('Unique Wellness Care | Trusted Healthcare in Delhi NCR');

    // Update meta tags dynamically
    this.meta.updateTag({
      name: 'description',
      content: 'Discover premier healthcare with Unique Wellness Care. NABL certified lab, home testing, same-day reports. Your well-being is our priority.'
    });

    // Add canonical link dynamically
    this.meta.addTag({ rel: 'canonical', href: '/' });
  }

  get name() { return this.contactForm.get('name'); }
  get email() { return this.contactForm.get('email'); }
  get phone_number() { return this.contactForm.get('phone_number'); }
  get message() { return this.contactForm.get('message'); }

  onSubmit() {
    if (this.contactForm.valid) {
      const formData = {
        ...this.contactForm.value,
        title: this.title
      };

      emailjs.send('service_kfbxk6b', 'template_zcgnaae', formData, 'fgwIjLHM8VzFPQ9dT')
        .then((response) => {
          this.successMessage = 'Your message has been sent successfully!';
          this.contactForm.reset();
          this.errorMessage = null; // Reset error message if success
          this.alert = true;

          // Hide success message after 1.5 seconds
          setTimeout(() => {
            this.successMessage = null;
            this.alert = false;
          }, 1500);
        }, (error) => {
          this.errorMessage = 'There was an error sending your message. Please try again.';
          console.error('EmailJS Error:', error);
          this.successMessage = null; // Reset success message if error
        });
    } else {
      this.errorMessage = 'Please fill in all required fields.';
      this.successMessage = null; // Reset success message if form invalid
      this.contactForm.markAllAsTouched(); // Mark all fields as touched to trigger validation messages
    }
  }
}
