import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-book-now',
  templateUrl: './book-now.component.html',
  styleUrls: ['./book-now.component.scss']
})
export class BookNowComponent implements OnInit {
  title='Book Now';
  constructor() { }

  ngOnInit(): void {
  }

}
