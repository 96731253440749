import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import{ HttpClient } from '@angular/common/http'

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  title='Request A Call Back !';
  alert: boolean=false;
  url='https://www.uniquewellnesscare.com/backend/saveDetails.php';
  // @Input() title = 'Request A Call Back !';
  contactForm: FormGroup;
  constructor(private httpClient: HttpClient) {
    
  }
  ngOnInit(){
    this.contactForm= new FormGroup({
      title: new FormControl(this.title),
      name: new FormControl('',[Validators.required]),
      phone_number: new FormControl('',[Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]),
    })
  }
    get name(){return this.contactForm.get('name')}
    get phone_number(){return this.contactForm.get('phone_number')}
  
  onSubmit(){
    if(this.contactForm.invalid){
      return;
    }
    this.httpClient.post(this.url,this.contactForm.value).subscribe((data)=>{
      if(data['error']===0){
       this.alert = true;
       setTimeout(() => {
       this.alert = false;
        }, 5000)
      };
      
    },
    error=>{
      // DO Something when error comes
      // this.alert=true;
    })
    this.contactForm.reset();
  }
}
