<app-alert-msg  *ngIf="alert"></app-alert-msg>

<div class="main-banner pb-0">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-5 col-md-12">
                <div class="main-banner-content">
                    <span>#zaroorat Hai</span>
                    <h1>Get Your Health Check-up Done Today !</h1>
                    <p>We Help You In Every Step!</p>
                    <a routerLink="/contact" class="btn btn-primary">Make An Appointment</a>
                </div>
            </div>
            <div class="col-lg-1 col-md-12"></div>
            <div class="col-lg-6 col-md-12">
                <div class="banner-image">
                    <img src="assets/img/banner-img1.png" alt="image">
                    <img src="assets/img/bg-shape2.png" alt="image">
                </div>
            </div>
        </div>
    </div>

    <div class="shape-img1 rotateme"><img src="assets/img/shape-image/1.png" alt="image"></div>
    <div class="shape-img2"><img src="assets/img/shape-image/2.png" alt="image"></div>
    <div class="shape-img3 rotateme"><img src="assets/img/shape-image/3.png" alt="image"></div>
    <div class="shape-img4"><img src="assets/img/shape-image/4.png" alt="image"></div>
    <div class="shape-img5"><img src="assets/img/shape-image/5.png" alt="image"></div>
    <div class="shape-img6"><img src="assets/img/shape-image/6.png" alt="image"></div>
    <div class="shape-img7"><img src="assets/img/shape-image/7.png" alt="image"></div>
    <div class="shape-img8"><img src="assets/img/shape-image/8.png" alt="image"></div>
</div>


<section class="boxes-area">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6 d-flex align-items-stretch">
                <div class="single-box">
                    <div class="icon">
                        <i class="flaticon-laboratory"></i>
                    </div>
                    <h3> Free Home Collection</h3>
                    <p>Experience the convenience of healthcare on your terms.
                         Our free home collection service allows you to schedule tests from the comfort of your home or workplace. 
                         No more waiting in long queues or traveling to a lab, ensuring a hassle-free and stress-free testing experience.
                    </p>
                    <a routerLink="/" class="learn-more-btn">Learn More <i class="flaticon-arrow-pointing-to-right"></i></a>
                    <div class="shape-box">
                        <img src="assets/img/shape-image/9.png" alt="image">
                        <img src="assets/img/shape-image/10.png" alt="image">
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6 d-flex align-items-stretch">
                <div class="single-box bg-43c784">
                    <div class="icon">
                        <i class="flaticon-microscope"></i>
                    </div>
                    <h3>Reports on Same Day</h3>
                    <p>At Unique Wellness Care, we understand the importance of timely results.
                         That's why we offer a commitment to deliver your test reports on the same day.
                          Our efficient processes and cutting-edge technology ensure that you don't have to wait long for the answers you seek.
                    </p>
                    <a routerLink="/" class="learn-more-btn">Learn More <i class="flaticon-arrow-pointing-to-right"></i></a>
                    <div class="shape-box">
                        <img src="assets/img/shape-image/9.png" alt="image">
                        <img src="assets/img/shape-image/10.png" alt="image">
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3 col-sm-6 offset-sm-3 d-flex align-items-stretch">
                <div class="single-box bg-f59f00">
                    <div class="icon">
                        <i class="flaticon-laboratory-1"></i>
                    </div>
                    <h3>NABL Certified Labs</h3>
                    <p>Trust is paramount when it comes to healthcare. At Unique Wellness Care, we take quality seriously. Our labs are NABL certified,
                         assuring you of the highest standards in accuracy and reliability.
                          NABL certification is a testament to our commitment to delivering precise and trustworthy results.</p>
                          
                    <a routerLink="/" class="learn-more-btn">Learn More <i class="flaticon-arrow-pointing-to-right"></i></a>
                    <div class="shape-box">
                        <img src="assets/img/shape-image/9.png" alt="image">
                        <img src="assets/img/shape-image/10.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>


<section class="about-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-image">
                    <img src="assets/img/about-img1.png" alt="image">
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <span>About Us</span>
                    <h2>Creating A Healthier Tomorrow For You</h2>
                    <p>Unique Wellness Care is a leading health laboratory in India. We offer a wide range of tests at affordable prices, and our results are fast and accurate. We are committed to providing our patients with the best possible experience, and we are proud of our reputation for excellence. With a legacy of 15+  years in healthcare excellence, we combine advanced technology with compassionate care.
                    </p>
                    <ul class="about-features-list">
                        <li><i class="flaticon-check-mark"></i> Free Sample Pickup</li>
                        <li><i class="flaticon-check-mark"></i> Honest Prices</li>
                        <li><i class="flaticon-check-mark"></i> Doctor Consultaion</li>
                        <li><i class="flaticon-check-mark"></i> Healthcare Labs</li>
                        <li><i class="flaticon-check-mark"></i> Guaranted Accuracy</li>
                        <li><i class="flaticon-check-mark"></i> Technology and Equipments</li>
                        <li><i class="flaticon-check-mark"></i> Full Automated</li>
                        <li><i class="flaticon-check-mark"></i> 100% Calibration and Results</li>
                    </ul>
                    <div class="btn-box">
                        <a href="#" class="btn btn-primary">Read More</a>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>

   
</section>

<section class="services-area">
    <div class="container">
        <div class="section-title">
            <span>Services</span>
            <h2>There is no Doubt To Get Your Service Over Year of Experience</h2>
            <a routerLink="/contact" class="btn btn-secondary">Tell Us More</a>
        </div>

        <div class="row gx-md-5 gy-5 ">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-coding"></i>
                    </div>
                    <h3> Blood Tests
                    </h3>
                    <p>Our blood tests offer deep insights into your health, helping you make informed decisions. From routine screenings to specialized panels, our experienced team ensures accurate results. Discover the power of knowledge with our blood tests.
                    </p>
                    <a routerLink="/" class="learn-more-btn">Learn More <i class="flaticon-arrow-pointing-to-right"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon bg-24b765">
                        <i class="flaticon-sugar-blood-level"></i>
                    </div>
                    <h3>Cardiac Health</h3>
                    <p>Your heart deserves the best care. Our cardiac health services include thorough assessments and screenings to monitor your cardiovascular well-being. Trust us to keep your heart in good hands.
                    </p>
                    <a routerLink="/" class="learn-more-btn">Learn More <i class="flaticon-arrow-pointing-to-right"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon bg-f59f00">
                        <i class="flaticon-computer"></i>
                    </div>
                    <h3> Diabetes Management
                    </h3>
                    <p>Diabetes requires careful management. Our diabetes management services provide the support and guidance you need to control this condition effectively. Take charge of your health with us.
                    </p>
                    <a routerLink="/" class="learn-more-btn">Learn More <i class="flaticon-arrow-pointing-to-right"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon bg-f7b232">
                        <i class="flaticon-microscope"></i>
                    </div>
                    <h3>Women's Health</h3>
                    <p>Women's health is unique and requires specialized care. Our comprehensive women's health services address your specific needs, from screenings to gynecological health. We're here to support your well-being at every stage of life.
                    </p>
                    <a routerLink="/" class="learn-more-btn">Learn More <i class="flaticon-arrow-pointing-to-right"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon bg-fe5d24">
                        <i class="flaticon-green-earth"></i>
                    </div>
                    <h3>Senior Citizen Health Checkup
                    </h3>
                    <p>As you age, your health needs change. Our senior citizen health checkup is tailored to address the unique health concerns of seniors. Stay active, independent, and healthy with our comprehensive assessments.
                    </p>
                    <a routerLink="/" class="learn-more-btn">Learn More <i class="flaticon-arrow-pointing-to-right"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon bg-45c27c">
                        <i class="flaticon-ai"></i>
                    </div>
                    <h3>Full Body Check-ups</h3>
                    <p>Your health is a puzzle with many pieces. Our full body check-ups provide a holistic view of your well-being. From head to toe, we leave no stone unturned in our quest to keep you healthy and thriving.
                    </p>
                    <a routerLink="/" class="learn-more-btn">Learn More <i class="flaticon-arrow-pointing-to-right"></i></a>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="appointment-area pt-5">
    <div class="container">
        <div class="section-title text-center">
            <span class="bg-f59f00">Contact us today!</span>
            <h2>Have questions or are you ready to schedule your tests? 
            </h2>
           
        </div>
    </div>
    <div class="appointment-inner-area jarallax" data-jarallax='{"speed": 0.3}'>
        <div class="container">
          <div class="appointment-form">
            <form [formGroup]="contactForm" (ngSubmit)="onSubmit()">
              <div class="getInTouch">
                <h3>{{ title }}</h3>
      
                <div class="form-group">
                  <input type="text" class="form-control" name="name" formControlName="name" placeholder="Name*" required>
                  <small class="errorField" *ngIf="name.invalid && name.touched">Name is Required <sup>*</sup></small>
                </div>
      
                <div class="form-group">
                  <input type="email" class="form-control" name="email" formControlName="email" placeholder="Email*" required>
                  <small class="errorField" *ngIf="email.invalid && email.touched">
                    Email is Required <sup>*</sup> and must be a valid email address
                  </small>
                </div>
      
                <div class="form-group">
                  <input type="text" class="form-control" name="phone_number" formControlName="phone_number" placeholder="Phone No*" required>
                  <small class="errorField" *ngIf="phone_number.invalid && phone_number.touched">
                    Phone Number is Required <sup>*</sup> and must be a valid 10-digit number
                  </small>
                </div>
      
              
                <button type="submit" class="btn btn-primary">Submit</button>
              </div>
            </form>
            
<div *ngIf="successMessage" class="alert alert-success" role="alert">
    {{ successMessage }}
  </div>
          </div>
        </div>
      </div>
      
</section>

<section class="funfacts-area bg-fff7f4">
    <div class="container">
        <app-funfacts></app-funfacts>
    </div>

    <div class="map-box1"><img src="assets/img/map1.png" alt="image"></div>
</section>

<section class="pricing-area ptb-120 bg-fff7f4">
    <div class="container">
        <div class="section-title text-center">
            <span class="bg-ff5d24">Pricing</span>
            <h2>Affordable Health Package Your Pricing Plan But it Easy</h2>
            <p>MOST POPULAR FULL BODY PACKAGES</p>
        </div>

        <div class="row">
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-pricing-box bg-3fc581">
                    <div class="icon">
                        <i class="fas fa-user"></i>
                    </div>
                    <h3>Basic Package</h3>
                    <ul class="pricing-features-list">
                        <li><i class="flaticon-check-mark-1"></i> Liver Function Test</li>
                        <li><i class="flaticon-check-mark-1"></i> Kidney Function Test</li>
                        <li><i class="flaticon-check-mark-1"></i> Lipid Profile</li>
                    </ul>
                    <div class="price">
                        ₹ 799 /- 

                    </div>
                    <a routerLink="/a-profile" class="default-btn">Know More</a>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-pricing-box bg-3fc581">
                    <div class="icon">
                        <i class="fas fa-coins"></i>
                    </div>
                    <h3>Gold Package</h3>
                    <ul class="pricing-features-list">
                        <li><i class="flaticon-check-mark-1"></i> Liver Function Test</li>
                        <li><i class="flaticon-check-mark-1"></i> Kidney Function Test</li>
                        <li><i class="flaticon-check-mark-1"></i> Lipid Profile</li>
                    </ul>
                    <div class="price">
                        ₹ 999 /- 

                    </div>
                    <a routerLink="/b-profile" class="default-btn">Know More</a>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-pricing-box bg-3fc581">
                    <div class="icon">
                        <i class="fas fa-hand-holding-usd"></i>
                    </div>
                    <h3>Premium Package</h3>
                    <ul class="pricing-features-list">
                        <li><i class="flaticon-check-mark-1"></i> Liver Function Test</li>
                        <li><i class="flaticon-check-mark-1"></i> Kidney Function Test</li>
                        <li><i class="flaticon-check-mark-1"></i> Lipid Profile</li>
                    </ul>
                    <div class="price">
                        ₹ 1499 /- 

                    </div>
                    <a routerLink="/c-profile" class="default-btn">Know More</a>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-pricing-box bg-3fc581">
                    <div class="icon">
                        <i class="fas fa-hand-holding-heart"></i>
                    </div>
                    <h3>Platinum Package</h3>
                    <ul class="pricing-features-list">
                        <li><i class="flaticon-check-mark-1"></i> Liver Function Test</li>
                        <li><i class="flaticon-check-mark-1"></i> Kidney Function Test</li>
                        <li><i class="flaticon-check-mark-1"></i> Lipid Profile</li>
                    </ul>
                    <div class="price">
                        ₹ 2499 /- 


                    </div>
                    <a routerLink="/d-profile" class="default-btn">Know More</a>
                </div>
            </div>


           

            
        </div>
    </div>
</section>

<section class="testimonial-area ptb-120 ">
    <div class="container">
        <div class="section-title text-center">
            <span class="bg-ff5d24">Testimonial</span>
            <h2>What our clients say about us</h2>
        </div>
    </div>

    <div class="testimonial-slides owl-carousel owl-theme">
        <div class="single-testimonial-item">
            <p>The free home collection service is a game-changer. Thank you for making healthcare so accessible!
            </p>
            <div class="client-info">
               
                <h3>Sachin Bhatt
                </h3>
                <span>Ghaziabad</span>
            </div>
            <i class="flaticon-left-quotes-sign"></i>
        </div>

        <div class="single-testimonial-item">
            <p>Prompt results and top-notch service. I couldn't be happier with my experience .</p>
            <div class="client-info">
              
                <h3>Priya Shahee
                </h3>
                <span>Noida</span>
            </div>
            <i class="flaticon-left-quotes-sign"></i>
        </div>

        <div class="single-testimonial-item">
            <p>I've been relying on UWC for years, and they consistently exceed my expectations for healthcare.
            </p>
            <div class="client-info">
              
                <h3>Palak Shikha
                </h3>
                <span>Greater Noida</span>
            </div>
            <i class="flaticon-left-quotes-sign"></i>
        </div>

        <div class="single-testimonial-item">
            <p>Unique Wellness Care's commitment to accuracy and patient care sets them apart. Truly a one-stop solution.
            <div class="client-info">
               
                <h3>Nitin Sharma
                </h3>
                <span> Delhi</span>
            </div>
            <i class="flaticon-left-quotes-sign"></i>
        </div>

        <div class="single-testimonial-item">
            <p>The comprehensive range of tests and the professionalism of the staff make Unique Wellness Care my first choice.
            </p>
            <div class="client-info">
               
                <h3>Sandeep Sethi
                </h3>
                <span>Faridabad</span>
            </div>
            <i class="flaticon-left-quotes-sign"></i>
        </div>
    </div>
</section>

<section class="blog-area ptb-120 bg-fff7f4">
    <div class="container">
        <div class="section-title text-center">
            <span class="bg-ff5d24">News Update</span>
            <h2>News & Blog</h2>
           
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="post-image">
                        <a routerLink="/"><img src="assets/img/blog-img9.jpg" alt="image"></a>
                        <div class="date">12 Sep, 2023</div>
                    </div>
                    <div class="post-content">
                        <span>By: <a routerLink="/">Rohit Sharma</a></span>
                        <h3><a routerLink="/"> The Importance of Regular Health Lab Tests
                        </a></h3>
                        <p>Regular health lab tests can help to identify potential health problems early on, when they are most treatable. This is why it is important to get regular tests, even if you feel healthy.</p>
                        <a routerLink="/" class="learn-more-btn">Learn More <i class="flaticon-arrow-pointing-to-right"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="post-image">
                        <a routerLink="/"><img src="assets/img/blog-img8.jpg" alt="image"></a>
                        <div class="date">14 Sep, 2023</div>
                    </div>
                    <div class="post-content">
                        <span>By: <a routerLink="/">Dhruv Sharma</a></span>
                        <h3><a routerLink="/"> How to Choose the Right Health Lab Test
                        </a></h3>
                        <p>There are many different health lab tests available, and it can be difficult to know which ones are right for you. This blog post will discuss how to choose the right tests for your individual needs.
                        </p>
                        <a routerLink="/" class="learn-more-btn">Learn More <i class="flaticon-arrow-pointing-to-right"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                <div class="single-blog-post">
                    <div class="post-image">
                        <a routerLink="/single-blog"><img src="assets/img/blog-img7.jpg" alt="image"></a>
                        <div class="date">16 Sep, 2023</div>
                    </div>
                    <div class="post-content">
                        <span>By: <a routerLink="/">Mohit Ananad</a></span>
                        <h3><a routerLink="/"> Common Health Lab Test Myths
                            </a></h3>
                        <p>There are many myths and misconceptions about health lab tests. This blog post will debunk some of the most common myths and provide you with accurate information about these important tests.
                        </p>
                        <a routerLink="/" class="learn-more-btn">Learn More <i class="flaticon-arrow-pointing-to-right"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> 
<section class="faq-area ptb-120">
    <div class="container">
        <div class="section-title text-center">
            <span>First time visit in lab?</span>
            <h2>Frequently Asked Questions</h2>
           
        </div>
        
        <div class="row pt-4">
            <div class="col-lg-6 col-md-12">
                <div class="faq-accordion">
                    <ul class="accordion">
                        <li class="accordion-item">
                            <span class="accordion-title">How can I schedule an appointment for a test?
                            </span>
                            <p class="accordion-content">Booking an appointment is easy. You can do it online through our website or by calling our dedicated phone line.
                            </p>
                        </li>
                        <li class="accordion-item">
                            <span class="accordion-title">How long does it take to receive my test results?
                            </span>
                            <p class="accordion-content"> Typically, results are available within 8-12 hours/days, depending on the test. Our team will provide a specific time frame when you schedule.
                            </p>
                        </li>
                        <li class="accordion-item">
                            <span class="accordion-title">Do you offer the home collection service in all areas?
                            </span>
                            <p class="accordion-content">Yes, our free home collection service covers a wide area. Please check with us to confirm coverage in your locality.
                            </p>
                        </li>
                       
                    </ul>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="faq-accordion">
                    <ul class="accordion">
                        <li class="accordion-item">
                            <span class="accordion-title"> What payment methods do you accept?
                            </span>
                            <p class="accordion-content">We accept various payment methods, including cash, credit/debit cards, UPI and digital wallets. Details can be found on our website.
                            </p>
                        </li>
                        <li class="accordion-item">
                            <span class="accordion-title">Are there any specific fasting requirements for certain tests?
                            </span>
                            <p class="accordion-content">Yes, some tests may require fasting. Our team will provide detailed instructions when you book your test to ensure accurate results.
                            </p>
                        </li>
                        <li class="accordion-item">
                            <span class="accordion-title">Are the home sample collection services safe and hygienic?</span>
                            <p class="accordion-content">Yes, Unique Wellness Care home sample collection services are safe and hygienic.
                            </p>
                        </li>
                       
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="cta-area ptb-120">
    <div class="container">
        <div class="cta-content">
            <h3>We'll ensure you always get the best Results</h3>
            <!--<a class="navbar-brand" routerLink="/"><img src="assets/img/logo.png" class="im" alt="logo"></a>-->

            <ul class="list-inline pt-5">
                <li class="list-inline-item"><a class="tel-a" href="tel:91 8510025135"><i class="fas fa-mobile-alt"></i> +91 8510025135
                </a></li>
                <li class="list-inline-item"><a class="tel-a" href="tel:+91 8181815299"><i class="fas fa-mobile-alt"></i> +91 8181815299
                </a></li>
            </ul>
            <ul class="list-inline pt-0">
                <li class="list-inline-item"><a class="tel-a" href="mailto:info@uniquewellnesscare.com"><i class="fas fa-envelope"></i> info&#64;uniquewellnesscare.com</a></li>
                
            </ul>
            <a routerLink="/contact" class="btn btn-primary">Contact Us</a>
        </div>
    </div>
</section>
