import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-any-other-query',
  templateUrl: './any-other-query.component.html',
  styleUrls: ['./any-other-query.component.scss']
})
export class AnyOtherQueryComponent implements OnInit {
  title='Any Other Query';
  constructor() { }

  ngOnInit(): void {
  }

}
