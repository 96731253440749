import { Component, OnInit, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import emailjs from '@emailjs/browser';

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss']
})
export class ContactFormComponent implements OnInit {
  @Input() title = ''; // Accept title as an input property
  contactForm: FormGroup;
  successMessage: string | null = null;
  errorMessage: string | null = null;

  constructor() {
    this.contactForm = new FormGroup({
      name: new FormControl('', Validators.required),
      email: new FormControl('', [Validators.required, Validators.email]),
      phone_number: new FormControl('', [
        Validators.required,
        Validators.pattern('^((\\+91-?)|0)?[0-9]{10}$') // Add a pattern for phone number validation
      ]),
      subject: new FormControl('', Validators.required),
      message: new FormControl(''),
    });
  }

  ngOnInit(): void {}

  get name() { return this.contactForm.get('name'); }
  get email() { return this.contactForm.get('email'); }
  get phone_number() { return this.contactForm.get('phone_number'); }
  get subject() { return this.contactForm.get('subject'); }
  get message() { return this.contactForm.get('message'); }

  onSubmit() {
    if (this.contactForm.valid) {
      const formData = {
        ...this.contactForm.value,
        title: this.title
      };

      emailjs.send('service_kfbxk6b', 'template_zcgnaae', formData, 'fgwIjLHM8VzFPQ9dT')
        .then((response) => {
          this.successMessage = 'Your message has been sent successfully!';
          this.contactForm.reset();
          this.errorMessage = null; // Reset error message if success

          // Hide success message after 1.5 seconds
          setTimeout(() => {
            this.successMessage = null;
          }, 1500);
        }, (error) => {
          this.errorMessage = 'There was an error sending your message. Please try again.';
          console.error('EmailJS Error:', error);
          this.successMessage = null; // Reset success message if error
        });
    } else {
      this.errorMessage = 'Please fill in all required fields.';
      this.successMessage = null; // Reset success message if form invalid
      this.contactForm.markAllAsTouched(); // Mark all fields as touched to trigger validation messages
    }
  }
}
