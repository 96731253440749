

<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>About Us</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>About Us</li>
            </ul>
        </div>
    </div>
</div>

<section class="about-our-company ptb-120 pb-0">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-company-content">

                    <h2>Who We Are
                    </h2>

                    <p>
                        We are Unique Wellness Care, a dedicated and trusted healthcare provider in Delhi NCR, India.
                        Our commitment is to deliver the highest quality healthcare services, driven by a passion for
                        accuracy, compassion for our patients, and a commitment to innovation. We've been a cornerstone
                        of the local healthcare community since 2014.
                    </p>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-company-image">
                    <img src="assets/img/1.svg" alt="image">
                </div>
            </div>

           






        </div>
    </div>

</section>
<section class="about-our-company bg-1234 ptb-120 pb-0">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12 order-2 order-md-1">
                <div class="about-inner-company-image">
                    <img src="assets/img/2.svg" alt="image">
                </div>
            </div>

            <div class="col-lg-6 col-md-12 order-1 order-md-2">
                <div class="about-inner-company-content">
                    <h2> Our Journey
                    </h2>
                    <p>Our journey began in 2014 when we set out to redefine healthcare in Delhi. Over the years, we
                        have achieved several milestones, constantly pushing the boundaries of medical excellence.
                        Today, we stand as a symbol of reliability and compassion, driven by an unwavering commitment to
                        our patients' well-being. </p>

                </div>
            </div>
        </div>
    </div>
</section>
<section class="about-our-company ptb-120 pb-0">
    <div class="container">
        <div class="row align-items-center">
           
            <div class="col-lg-6 col-md-12">
                <div class="about-company-content">

                    <h2>Our Core Values

                    </h2>

                    <p>
                        Our core values define who we are and how we operate. Accuracy, compassion, and innovation are
                        the pillars upon which we built our organization. These values guide us every day as we provide
                        healthcare services that make a difference in people's lives.

                    </p>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-company-image">
                    <img src="assets/img/3.svg" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>
<section class="about-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-image">
                    <img src="assets/img/about-img1.png" alt="image">
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <span>Certifications and Accreditations</span>
                    <h2>Accredited by NABL: Ensuring the Highest Quality Standards</h2>
                    <p>At Unique Wellness Care, we take immense pride in our commitment to delivering the highest
                        quality
                        healthcare services. This commitment is exemplified by our accreditation by the National
                        Accreditation
                        Board for Testing and Calibration Laboratories (NABL).</p>
                    <h3 class="hd29 pt-3">What is NABL?
                    </h3>


                    <p class="accordion-content">NABL, a constituent board of the Quality Council of India (QCI), is the
                        premier accreditation body responsible for accrediting laboratories in India. NABL accreditation
                        is a prestigious recognition that signifies adherence to international standards and practices
                        in laboratory testing and calibration. It is awarded only to laboratories that demonstrate
                        exceptional competence, precision, and reliability in their operations.


                    </p>

                    <h3 class="hd29 pt-3">Why NABL Accreditation Matters</h3>


                    <p class="accordion-content">NABL, a constituent board of the Quality Council of India
                        (QCI), is the premier accreditation body responsible for accrediting laboratories in
                        India. NABL accreditation is a prestigious recognition that signifies adherence to
                        international standards and practices in laboratory testing and calibration. It is
                        awarded only to laboratories that demonstrate exceptional competence, precision, and
                        reliability in their operations.

                    </p>
                </div>
            </div>
        </div>
    </div>


</section>

<section class="services-area">
    <div class="container">
        <div class="section-title mb-0 text-center">
            <span>uwc</span>
            <h2>The Benefits of NABL Accreditation</h2>

        </div>

        <div class="row gx-md-5 gy-5 mt-2">
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-coding"></i>
                    </div>
                    <h3>Quality Assurance
                    </h3>
                    <p>NABL accreditation ensures that our laboratory consistently produces accurate and reliable test
                        results.

                    </p>

                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon bg-24b765">
                        <i class="flaticon-sugar-blood-level"></i>
                    </div>
                    <h3>International Recognition</h3>
                    <p> NABL accreditation aligns us with global best practices and standards in the laboratory
                        industry.

                    </p>

                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon bg-f59f00">
                        <i class="flaticon-computer"></i>
                    </div>
                    <h3> Confidence and Trust
                    </h3>
                    <p>Clients can have full confidence in the quality and credibility of our services.

                    </p>

                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon bg-f7b232">
                        <i class="flaticon-microscope"></i>
                    </div>
                    <h3>Regulatory Compliance</h3>
                    <p> We strictly adhere to regulatory requirements, ensuring the utmost integrity in our operations.

                    </p>

                </div>
            </div>


        </div>
    </div>
</section>
<section class="about-our-company ptb-120 pb-0">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-company-content">

                    <h2>State-of-the-Art Technology and Facilities

                    </h2>

                    <p>
                        To ensure that we provide the best possible care, we've invested in state-of-the-art technology
                        and maintain modern facilities. Our commitment to innovation is evident in our approach to
                        healthcare, where advanced technology plays a crucial role in accurate diagnostics and patient
                        satisfaction.

                    </p>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-company-image">
                    <img src="assets/img/1.svg" alt="image">
                </div>
            </div>

          


        </div>
    </div>

</section>
<section class="about-our-company ptb-120  pb-0">
    <div class="container">
        <div class="row align-items-center">
<div class="col-lg-6 col-md-12 order-2 order-md-1">
    <div class="about-inner-company-image">
        <img src="assets/img/2.svg" alt="image">
    </div>
</div>

<div class="col-lg-6 col-md-12 order-1 order-md-2">
    <div class="about-inner-company-content">
        <h2> Our Commitment to Quality

        </h2>
        <p> Our unwavering commitment to quality is the driving force behind our services. We aim to set the
            industry standard for precision, reliability, and patient satisfaction. This commitment is not
            just a promise; it's our everyday practice.
        </p>

    </div>
</div>

</div>
</div>

</section>
<section class="appointment-area pt-5">
    <div class="container">
        <div class="section-title text-center">
            <span class="bg-f59f00">UWC</span>
            <h2>Get in Touch!
            </h2>
            <P>We're here to address your healthcare needs. Whether you have questions or want to schedule an
                appointment, we're just a call or click away. Contact us now, and let's begin your journey to better
                health.</P>


        </div>
    </div>

    <div class="appointment-inner-area jarallax">
        <div class="container">
            <div class="appointment-form">
                <form [formGroup]="contactForm" (ngSubmit)="onSubmit()">

                    <!-- Get In Touch For Home Page -->
                    <div class="getInTouch">
                        <h3>{{title}}</h3>
                        <div class="form-group">
                            <input type="text" class="form-control" name="name" formControlName="name"
                                placeholder="Name*" required>
                            <small class="errorField" *ngIf="name.invalid && name.touched">Name is Required
                                <sup>*</sup></small>
                        </div>
                        <div class="form-group">
                            <input type="email" class="form-control" name="email" formControlName="email"
                                placeholder="Email*" required>
                            <small class="errorField" *ngIf="email.invalid && email.touched">Email is Required
                                <sup>*</sup></small>
                        </div>
                        <div class="form-group">
                            <input type="text" class="form-control" name="phone_number" formControlName="phone_number"
                                placeholder="Phone No*" required>
                            <small class="errorField" *ngIf="phone_number.invalid && phone_number.touched">Phone Number
                                is Required <sup>*</sup></small>
                        </div>
                        <div class="form-group">
                            <textarea name="message" cols="30" rows="6" name="message" formControlName="message"
                                class="form-control" placeholder="Message"></textarea>
                        </div>
                        <button type="submit" class="btn btn-primary">Submit</button>
                    </div>

                </form>
            </div>
        </div>
    </div>
</section>

<!--<section class="mb-5 pt-5 pb-5">
    <div class="container">
        <div class="row">
            <div class="about-us_GlucoseToGenetics__V8ipx about-us_aboutUsContainer__AcIsX">

                <div class="col-md-12 text-center">
                    <h3>The Benefits of NABL Accreditation
                    </h3>
                    <p>At Unique Wellness Care, we take immense pride in our commitment to delivering the highest
                        quality healthcare services. This commitment is exemplified by our accreditation by the National
                        Accreditation Board for Testing and Calibration Laboratories (NABL).
                    </p>
                </div>
                <div class="col-md-12 text-center">
                    <div class="row mt-5">
                        <div class="col-md-4">
                            <div class="about-us_Div__z_ObW">
                                <div class="about-us_ImageDiv__sIWil"><img alt="Digital-first approach" loading="lazy"
                                        width="70" height="70" decoding="async" data-nimg="1"
                                        src="https://staticcdn.redcliffelabs.com/media/gallary-file/None/c3670215-be5e-4f37-93c6-ddccaa6e2406.svg"
                                        style="color: transparent;"></div><span>Digital-first<br>approach</span>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="about-us_Div__z_ObW">
                                <div class="about-us_ImageDiv__sIWil"><img alt="Accurate Reports within 24 Hrs"
                                        loading="lazy" width="70" height="70" decoding="async" data-nimg="1"
                                        src="https://staticcdn.redcliffelabs.com/media/gallary-file/None/8c790d5c-e918-4e86-a315-2cef89b82004.svg"
                                        style="color: transparent;"></div><span>Accurate Reports<br>within 24 Hrs</span>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="about-us_Div__z_ObW">
                                <div class="about-us_ImageDiv__sIWil"><img alt="On-demand 1-hour home collection"
                                        loading="lazy" width="70" height="70" decoding="async" data-nimg="1"
                                        src="https://staticcdn.redcliffelabs.com/media/gallary-file/None/115c0133-de53-41de-bc67-c6c2332c7397.svg"
                                        style="color: transparent;"></div><span>On-demand 1-hour<br>home
                                    collection</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>-->