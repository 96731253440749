<div class="row">
    <div class="col-lg-3 col-md-3 col-sm-3 col-12">
        <div class="single-funfacts">
            <h3><span class="odometer" data-count="25000">00</span></h3>
            <p>+ HAPPY CUSTOMER</p>
        </div>
    </div>

    <div class="col-lg-3 col-md-3 col-sm-3 col-12">
        <div class="single-funfacts">
            <h3><span class="odometer" data-count="55000">00</span></h3>
            <p>+ TESTS DONE</p>
        </div>
    </div>

    <div class="col-lg-3 col-md-3 col-sm-3 col-12">
        <div class="single-funfacts">
            <h3><span class="odometer" data-count="16">00</span></h3>
            <p>+ YEARS OF EXPERIENCE</p>
        </div>
    </div>

    <div class="col-lg-3 col-md-3 col-sm-3 col-12">
        <div class="single-funfacts">
            <h3><span class="odometer" data-count="25">00</span></h3>
            <p>+ LABS</p>
        </div>
    </div>
</div>