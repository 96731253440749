import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-for-a-dsa',
  templateUrl: './for-a-dsa.component.html',
  styleUrls: ['./for-a-dsa.component.scss']
})
export class ForADSAComponent implements OnInit {
  title='For A DSA';
  constructor() { }

  ngOnInit(): void {
  }

}
