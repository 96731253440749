import { Component, OnInit } from '@angular/core';
import{ HttpClient } from '@angular/common/http';
import { Title, Meta } from '@angular/platform-browser';
@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  title='Contact Us';
  constructor(private httpClient: HttpClient, private meta: Meta, private titleService: Title)  {
    
  }
  ngOnInit(): void {
    // Update title dynamically
   this.titleService.setTitle('Contact Unique Wellness Care | Reach Us for Best Healthcare ');

   // Update meta tags dynamically
   this.meta.updateTag({ name: 'description', content: 'Connect with Unique Wellness Care in Delhi NCR. NABL certified lab, home testing, same-day reports. Your health journey begins with us.' });

   // Add canonical link dynamically
   this.meta.addTag({ rel: 'canonical', href: '/contact' });
  }

}
