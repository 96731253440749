import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-business-partner',
  templateUrl: './business-partner.component.html',
  styleUrls: ['./business-partner.component.scss']
})
export class BusinessPartnerComponent implements OnInit {
  title='Business Partner';
  constructor() { }

  ngOnInit(): void {
  }

}
