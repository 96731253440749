<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Test List</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Test List</li>
            </ul>
        </div>
    </div>
</div>

<section class="shop-area ptb-120">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="woocommerce-topbar">
                    <div class="row align-items-center">
                        <div class="col-lg-8 col-md-7">
                           
                        </div>

                        <div class="col-lg-4 col-md-5">
                          <input class="form-control" id="myInput" type="text" placeholder="Search..">
                        </div>
                    </div>

                    <div class="row align-items-center">
                        <div class="col-lg-12 col-md-12">
                            <table class="table test-list table-bordered table-striped">
                                <thead>
                                  <tr class="test-heading">
                                    <th scope="col">S.N.</th>
                                    <th scope="col">TEST DESCRIPTION</th>
                                    <th scope="col">SAMPLE</th>
                                    <th scope="col">PRICE</th>
                                  </tr>
                                </thead>
                                <tbody>

                                    
                                </tbody>
                                <!--start-one-->
                                <tbody id="myTable">
                                    <tr>
                                        <th scope="row">HEAMATOLOGY</th>
                                        
                                      </tr>
                                  <tr>
                                    <th scope="row">1</th>
                                    <td>HB</td>
                                    <td>EDTA</td>
                                    <td>100</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">2</th>
                                    <td>TLC </td>
                                    <td>EDTA</td>
                                    <td>100</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">3</th>
                                    <td>DLC </td>
                                    <td>EDTA</td>
                                    <td>100</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">4</th>
                                    <td>ESR </td>
                                    <td>EDTA</td>
                                    <td>100</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">5</th>
                                    <td>RBC </td>
                                    <td>EDTA</td>
                                    <td>100</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">6</th>
                                    <td>MALARIA PARASITE (MP)  </td>
                                    <td>EDTA</td>
                                    <td>150</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">7</th>
                                    <td>BLOOD GROUP (BG)  </td>
                                    <td>EDTA</td>
                                    <td>100</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">8</th>
                                    <td>AEC  </td>
                                    <td>EDTA</td>
                                    <td>180</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">9</th>
                                    <td>PLATELET COUNT </td>
                                    <td>EDTA</td>
                                    <td>150</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">10</th>
                                    <td>BLEEDING TIME(BT) </td>
                                    <td>-</td>
                                    <td>80</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">11</th>
                                    <td>11 CLOTING TIME (CT)  </td>
                                    <td>-</td>
                                    <td>80</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">12</th>
                                    <td>PERIPHERAL SMEAR (PS) </td>
                                    <td>EDTA</td>
                                    <td>200</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">13</th>
                                    <td>PROTHROBIN TIME (PT)  </td>
                                    <td>S.CITRATE</td>
                                    <td>300</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">14</th>
                                    <td>APTT  </td>
                                    <td>S.CITRATE</td>
                                    <td>400</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">15</th>
                                    <td>HBA1C </td>
                                    <td>EDTA </td>
                                    <td>420</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">16</th>
                                    <td>PCV  </td>
                                    <td>EDTA </td>
                                    <td>150</td>
                                  </tr>
                                </tbody>
                                 <!--start-end-->
                                  <!--start-one-->
                                  <tbody id="myTable">
                                    <tr>
                                        <th scope="row">BIOCHEMISTRY</th>
                                        
                                      </tr>
                                  <tr>
                                    <th scope="row">17</th>
                                    <td>BLOOD SUGAR FASTING (FBS)</td>
                                    <td>FLORIDE</td>
                                    <td>50</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">18</th>
                                    <td>BLOOD SUGAR PP (BSPP)  </td>
                                    <td>FLORIDE</td>
                                    <td>50</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">19</th>
                                    <td>BLOOD SUGAR RANDOM (BSR)  </td>
                                    <td>FLORIDE</td>
                                    <td>50</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">20</th>
                                    <td>GTT PREGNANCY  </td>
                                    <td>FLORIDE</td>
                                    <td>350</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">21</th>
                                    <td>GTT </td>
                                    <td>FLORIDE</td>
                                    <td>350</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">22</th>
                                    <td>GCT  </td>
                                    <td>FLORIDE</td>
                                    <td>150</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">23</th>
                                    <td>BLOOD UREA </td>
                                    <td>SERUM</td>
                                    <td>150</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">24</th>
                                    <td>SERUM CREATININE  </td>
                                    <td>SERUM</td>
                                    <td>180</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">25</th>
                                    <td>SERUM URIC ACID'  </td>
                                    <td>SERUM</td>
                                    <td>150</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">26</th>
                                    <td>GAMMA GT  </td>
                                    <td>SERUM</td>
                                    <td>200</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">27</th>
                                    <td>BILIRUBIN TOTAL </td>
                                    <td>SERUM</td>
                                    <td>150</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">28</th>
                                    <td>BILIRUBIN DIRECT </td>
                                    <td>SERUM</td>
                                    <td>150</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">29</th>
                                    <td>BILIRUBIN INDIRECT </td>
                                    <td>SERUM</td>
                                    <td>250</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">30</th>
                                    <td>BILIRUBIN INDIRECT </td>
                                    <td>SERUM</td>
                                    <td>150</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">31</th>
                                    <td>ALBUMIN </td>
                                    <td>SERUM</td>
                                    <td>150</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">32</th>
                                    <td>GLOBULIN</td>
                                    <td>SERUM</td>
                                    <td>150</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">33</th>
                                    <td>A/G RATIO</td>
                                    <td>SERUM</td>
                                    <td>250</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">34</th>
                                    <td>SGOT</td>
                                    <td>SERUM</td>
                                    <td>150</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">35</th>
                                    <td>SGPT </td>
                                    <td>SERUM</td>
                                    <td>150</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">36</th>
                                    <td>SODIUM  </td>
                                    <td>SERUM</td>
                                    <td>225</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">37</th>
                                    <td>POTASSIUM  </td>
                                    <td>SERUM</td>
                                    <td>225</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">38</th>
                                    <td>CHLORIDE </td>
                                    <td>SERUM</td>
                                    <td>225</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">39</th>
                                    <td>SERUM CALCIUM </td>
                                    <td>SERUM</td>
                                    <td>150</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">40</th>
                                    <td>SERUM PHOSPHORUS </td>
                                    <td>SERUM</td>
                                    <td>180</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">41</th>
                                    <td>SERUM IRON </td>
                                    <td>SERUM</td>
                                    <td>300</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">42</th>
                                    <td>TOTAL IRON BINDING CAPACITY </td>
                                    <td>SERUM</td>
                                    <td>300</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">43</th>
                                    <td>% TRANSFERRIN SATURATION </td>
                                    <td>SERUM</td>
                                    <td>450</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">44</th>
                                    <td>BUN/SCREATININE RATIO  </td>
                                    <td>SERUM</td>
                                    <td>300</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">45</th>
                                    <td>CPK  </td>
                                    <td>SERUM</td>
                                    <td>450</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">46</th>
                                    <td>CPK MB  </td>
                                    <td>SERUM</td>
                                    <td>450</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">47</th>
                                    <td>LACTATE DEHYDROGENASE (LDH)  </td>
                                    <td>SERUM</td>
                                    <td>550</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">48</th>
                                    <td>TRIGLYCERIDES  </td>
                                    <td>SERUM</td>
                                    <td>180</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">49</th>
                                    <td>TOTAL CHOLESTEROL  </td>
                                    <td>SERUM</td>
                                    <td>180</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">50</th>
                                    <td>TOTAL CHOL/HDL CHOLESTEROL RATIO   </td>
                                    <td>SERUM</td>
                                    <td>360</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">51</th>
                                    <td>LDL/HDL CHOLESTEROL RATIO </td>
                                    <td>SERUM</td>
                                    <td>360</td>
                                  </tr>
                              
                              
                              
                              
                              
                                
                                 
                                </tbody>
                                 <!--start-end-->

                                         <!--start-one-->
                                         <tbody id="myTable">
                                    <tr>
                                        <th scope="row">SEROLOGY</th>
                                        
                                      </tr>
                                  <tr>
                                    <th scope="row">52</th>
                                    <td>CRP (QUANTITATIVE) </td>
                                    <td>SERUM</td>
                                    <td>450</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">53</th>
                                    <td>CRP (QUALITATIVE)  </td>
                                    <td>SERUM</td>
                                    <td>450</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">54</th>
                                    <td>RA FACTOR (QUANTITATIVE)  </td>
                                    <td>SERUM</td>
                                    <td>450</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">55</th>
                                    <td>RA FACTOR (QUALITATIVE)  </td>
                                    <td>SERUM</td>
                                    <td>600</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">56</th>
                                    <td>ASO (QUANTITATIVE)   </td>
                                    <td>SERUM</td>
                                    <td>450</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">57</th>
                                    <td>ASO (QUALITATIVE)  </td>
                                    <td>SERUM</td>
                                    <td>600</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">58</th>
                                    <td>VDRL  </td>
                                    <td>SERUM</td>
                                    <td>380</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">59</th>
                                    <td>HEPATITIS B SURFACE ANTIGEN (HBsAG) </td>
                                    <td>SERUM</td>
                                    <td>450</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">60</th>
                                    <td>HIV ANTIBODY I & II   </td>
                                    <td>SERUM</td>
                                    <td>450</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">61</th>
                                    <td>HEPATITIS C VIRUS (HCV)  </td>
                                    <td>SERUM</td>
                                    <td>450</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">62</th>
                                    <td>DENGUE NS-1 ANTIGEN  </td>
                                    <td>SERUM</td>
                                    <td>1200</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">63</th>
                                    <td>DENGUE IGG & IGM </td>
                                    <td>SERUM</td>
                                    <td>1200</td>
                                  </tr>
                                
                                  <tr>
                                    <th scope="row">64</th>
                                    <td>CHIKUNGUNYA IGM   </td>
                                    <td>SERUM</td>
                                    <td>1200</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">65</th>
                                    <td>WIDAL TEST (TUBE METHOD) </td>
                                    <td>SERUM</td>
                                    <td>300</td>
                                  </tr>
                                
                                  <tr>
                                    <th scope="row">66</th>
                                    <td>WIDAL TEST (SLIDE METHOD)  </td>
                                    <td>SERUM</td>
                                    <td>150</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">67</th>
                                    <td>MONTOUX TEST  </td>
                                    <td>-</td>
                                    <td>300</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">68</th>
                                    <td>TYPHIDOT IGG & IGM  </td>
                                    <td>SERUM</td>
                                    <td>450</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">69</th>
                                    <td>MALARIA SEROLOGY  </td>
                                    <td>SERUM</td>
                                    <td>450</td>
                                  </tr>
                                
                                
                                
                                
                                
                               
                                 
                                </tbody>
                                 <!--start-end-->
                                 
                                         <!--start-one-->
                                         <tbody id="myTable">
                                    <tr>
                                        <th scope="row">MICROBIOLOGY</th>
                                        
                                      </tr>
                                  <tr>
                                    <th scope="row">70</th>
                                    <td>URINE ROUTINE MICROSCOPIC (URINE R/M) </td>
                                    <td>URINE</td>
                                    <td>100</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">71</th>
                                    <td>URINE PREGNANCY TEST </td>
                                    <td>URINE</td>
                                    <td>150</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">72</th>
                                    <td> URINE PROTEIN SCREATININE RATIO SPOT </td>
                                    <td>URINE</td>
                                    <td>450</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">73</th>
                                    <td>URINE ALBUMIN SCREATININE RATIO SPOT </td>
                                    <td>URINE</td>
                                    <td>450</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">74</th>
                                    <td>URINE BILE SALTS (BS)  </td>
                                    <td>URINE</td>
                                    <td>250</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">75</th>
                                    <td>URINE PIGMENT (BP)   </td>
                                    <td>URINE</td>
                                    <td>250</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">76</th>
                                    <td>UROBILINOGEN   </td>
                                    <td>URINE</td>
                                    <td>250</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">77</th>
                                    <td>URINE KETONE/ACTONE </td>
                                    <td>URINE</td>
                                    <td>250</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">78</th>
                                    <td>24 HOURS URINARY PROTEIN  </td>
                                    <td>URINE</td>
                                    <td>600</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">79</th>
                                    <td>24 HOURS URINARY SCREATININE </td>
                                    <td>URINE</td>
                                    <td>600</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">80</th>
                                    <td>MICRO ALBUMINURIA (SPOT)  </td>
                                    <td>URINE</td>
                                    <td>450</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">81</th>
                                    <td>URINE FOR OCCULT BLOOD  </td>
                                    <td>URINE</td>
                                    <td>300</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">82</th>
                                    <td>URINE MICRO PROTEIN  </td>
                                    <td>URINE</td>
                                    <td>600</td>
                                  </tr>
                                 
                                
                                
                                
                                
                                
                               
                                 
                                </tbody>
                                 <!--start-end-->
                               <!--start-one-->
                               <tbody id="myTable">
                                    <tr>
                                        <th scope="row">CLINICAL PATHOLOGY</th>
                                        
                                      </tr>
                                  <tr>
                                    <th scope="row">83</th>
                                    <td>GRAM STAIN (ANY SPECIMEN)  </td>
                                    <td>-</td>
                                    <td>300</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">84</th>
                                    <td>AFB STAIN (ANY SPECIMEN)  </td>
                                    <td>-</td>
                                    <td>300</td>
                                  </tr>
                              
                                </tbody>
                                 <!--start-end-->

                                 <!--start-one-->
                                 <tbody id="myTable">
                                    <tr>
                                        <th scope="row">MICROSCOPIC EXAMINATION</th>
                                        
                                      </tr>
                                  <tr>
                                    <th scope="row">85</th>
                                    <td>SPUTUM FOR AFB  </td>
                                    <td>SPUTUM </td>
                                    <td>300</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">86</th>
                                    <td>STOOL ROUTINE & MICROSCOPY  </td>
                                    <td>STOOL</td>
                                    <td>150</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">87</th>
                                    <td>STOOL FOR OCCULT BLOOD </td>
                                    <td>STOOL</td>
                                    <td>250</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">88</th>
                                    <td>STOOL PH  </td>
                                    <td>STOOL</td>
                                    <td>250</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">89</th>
                                    <td>SEMEN ROUTINE & MICROSCOPIC </td>
                                    <td>SEMEN</td>
                                    <td>150</td>
                                  </tr>
                                
                              
                                </tbody>
                                 <!--start-end-->
                                  <!--start-one-->
                                  <tbody id="myTable">
                                    <tr>
                                        <th scope="row">CULTURE'S</th>
                                        
                                      </tr>
                                  <tr>
                                    <th scope="row">90</th>
                                    <td>URINE CULTURE & SENSITIVITY  </td>
                                    <td>URINE </td>
                                    <td>450</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">91</th>
                                    <td>STOOL CULTURE & SENSITIVITY  </td>
                                    <td>STOOL </td>
                                    <td>450</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">92</th>
                                    <td>BLOOD CULTURE & SENSITIVITY </td>
                                    <td>HEPRINE </td>
                                    <td>750</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">93</th>
                                    <td>PUS CULTURE & SENSITIVITY </td>
                                    <td>PUS </td>
                                    <td>750</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">94</th>
                                    <td>SEMEN CULTURE & SENSITIVITY </td>
                                    <td>SEMEN </td>
                                    <td>600</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">95</th>
                                    <td>NASAL SWAB CULTURE  </td>
                                    <td>-</td>
                                    <td>850</td>
                                  </tr>
                                
                                 
                              
                                </tbody>
                                 <!--start-end-->
                                  <!--start-one-->
                                  <tbody id="myTable">
                                    <tr>
                                        <th scope="row">SPECIAL INVESTIGATION</th>
                                        
                                      </tr>
                                  <tr>
                                    <th scope="row">96</th>
                                    <td>ALPHA FETO PROTEIN (AFP) </td>
                                    <td>SERUM </td>
                                    <td>800</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">97</th>
                                    <td>ANGIOTENSIN CONVERTING ENZYME (ACE) </td>
                                    <td>SERUM </td>
                                    <td>4000</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">98</th>
                                    <td>ANTI-MULLERIAN HORMONE (AMH) </td>
                                    <td>SERUM </td>
                                    <td>2500</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">99</th>
                                    <td>ANTI-CYCLIC CITRULLINATED PEPTIDE (ANTICCP) </td>
                                    <td>SERUM </td>
                                    <td>1300</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">100</th>
                                    <td>ANTI DS-DNA ANTIBODY </td>
                                    <td>SERUM </td>
                                    <td>850</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">101</th>
                                    <td>ANTI NUCLEAR ANTIBODY (ANA) </td>
                                    <td>SERUM </td>
                                    <td>750</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">102</th>
                                    <td>ANTI PHOSPHOLIPID IGG ANTIBODY </td>
                                    <td>SERUM </td>
                                    <td>900</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">103</th>
                                    <td>ANTI PHOSPHOLIPID IGG ANTIBODY </td>
                                    <td>SERUM </td>
                                    <td>900</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">102</th>
                                    <td>ANTI PHOSPHOLIPID IGM ANTIBODY </td>
                                    <td>SERUM </td>
                                    <td>900</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">103</th>
                                    <td>ANTI PHOSPHOLIPID IGM ANTIBODY </td>
                                    <td>SERUM </td>
                                    <td>900</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">104</th>
                                    <td>ANTI THYROGLOBULIN (ATG) </td>
                                    <td>SERUM </td>
                                    <td>900</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">105</th>
                                    <td>ANTI THYROID PEROXIDASE (ANTI-TPO) </td>
                                    <td>SERUM </td>
                                    <td>900</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">106</th>
                                    <td>BETA HUMAN CHORIONIC GONODOTROPIN (
                                        BETA HCG) </td>
                                    <td>SERUM </td>
                                    <td>900</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">107</th>
                                    <td>BIOPSY-LARGE SPECIMEN (< 5CM)</td>
                                    <td>-</td>
                                    <td>2500</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">108</th>
                                    <td>BIOPSY-MEDIUM SPECIMEN (UPTO 5CM)</td>
                                    <td>-</td>
                                    <td>1500</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">109</th>
                                    <td>BIOPSY-SMALL SPECIMEN (< 2 CM)</td>
                                    <td>-</td>
                                    <td>1200</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">110</th>
                                    <td>CA125 - OVARIAN CANCER MARKER</td>
                                    <td>SERUM</td>
                                    <td>1200</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">111</th>
                                    <td>1 CA15.3 - BREAST CANCER MARKER</td>
                                    <td>SERUM</td>
                                    <td>1500</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">112</th>
                                    <td> CA19.9 - PANCREATIC CANCER MARKER</td>
                                    <td>SERUM</td>
                                    <td>1800</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">113</th>
                                    <td> C-ANCA (PR3)</td>
                                    <td>SERUM</td>
                                    <td>1200</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">114</th>
                                    <td> CARCINO EMBRYONIC ANTIGEN (CEA)</td>
                                    <td>SERUM</td>
                                    <td>1600</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">115</th>
                                    <td> CARDIOLIPIN IGA ANTIBODY</td>
                                    <td>SERUM</td>
                                    <td>750</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">116</th>
                                    <td> CARDIOLIPIN IGG ANTIBODY</td>
                                    <td>SERUM</td>
                                    <td>750</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">117</th>
                                    <td> CARDIOLIPIN IGM ANTIBODY</td>
                                    <td>SERUM</td>
                                    <td>750</td>
                                  </tr>
                                 
                                  <tr>
                                    <th scope="row">118</th>
                                    <td> CD3/CD4/CD8</td>
                                    <td>EDTA</td>
                                    <td>1800</td>
                                  </tr>
                                 
                                  <tr>
                                    <th scope="row">119</th>
                                    <td> CERULOPLASMIN</td>
                                    <td>SERUM</td>
                                    <td>750</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">120</th>
                                    <td> CORTISOL </td>
                                    <td>SERUM</td>
                                    <td>700</td>
                                  </tr>
                                 
                                    
                                  <tr>
                                    <th scope="row">121</th>
                                    <td> C-PEPTIDE</td>
                                    <td>SERUM</td>
                                    <td>1800</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">122</th>
                                    <td>DEHYDROEPIANDROSTERONE SULPHATE
                                        (DHEA-S) </td>
                                    <td>SERUM</td>
                                    <td>700</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">123</th>
                                    <td>EGFR (ESTIMATED GLOMERULAR FILTRATION
                                        RATE) </td>
                                    <td>SERUM</td>
                                    <td>700</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">124</th>
                                    <td>ESTRADIOL (E2) </td>
                                    <td>SERUM</td>
                                    <td>700</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">125</th>
                                    <td>FERRITIN </td>
                                    <td>SERUM</td>
                                    <td>600</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">126</th>
                                    <td>FOLATE SERUM (FOLIC ACID) </td>
                                    <td>SERUM</td>
                                    <td>480</td>
                                  </tr>

                                  <tr>
                                    <th scope="row">127</th>
                                    <td>FOLLICLE STIMULATING HORMONE (FSH) </td>
                                    <td>SERUM</td>
                                    <td>400</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">128</th>
                                    <td>GLUCOSE 6 PHOSPHATE DEHYDROGENASE
                                        (G6PD)
                                        </td>
                                    <td>EDTA </td>
                                    <td>650</td>
                                  </tr>
                              
                                  <tr>
                                    <th scope="row">129</th>
                                    <td>GROWTH HORMONE (GH) </td>
                                    <td>SERUM</td>
                                    <td>1000</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">130</th>
                                    <td>HEMOGLOBIN VARIANT ANALYSIS-HPLC</td>
                                    <td>EDTA</td>
                                    <td>600</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">131</th>
                                    <td>HEPATITIS B VIRUS - VIRAL LOAD</td>
                                    <td>EDTA</td>
                                    <td>5500</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">132</th>
                                    <td>HEPATITIS C VIRUS - VIRAL LOAD</td>
                                    <td>EDTA</td>
                                    <td>5500</td>
                                  </tr>
                                 
                                  <tr>
                                    <th scope="row">133</th>
                                    <td>HIGH SENSITIVE CRP (HSCRP) </td>
                                    <td>SERUM </td>
                                    <td>4200</td>
                                  </tr>

                                  <tr>
                                    <th scope="row">134</th>
                                    <td>HIV - QUANTITATIVE (VIRUL LOAD)</td>
                                    <td>EDTA</td>
                                    <td>4200</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">135</th>
                                    <td>HIV-RNA PCR - QUALITATIVE</td>
                                    <td>EDTA</td>
                                    <td>2700</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">136</th>
                                    <td>HLA B-27</td>
                                    <td>EDTA</td>
                                    <td>1500</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">137</th>
                                    <td>HLA B-27 (PCR)</td>
                                    <td>EDTA</td>
                                    <td>2800</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">138</th>
                                    <td>HOMOCYSTEINE</td>
                                    <td>SERUM</td>
                                    <td>1100</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">139</th>
                                    <td>INSULIN-FASTING </td>
                                    <td>SERUM</td>
                                    <td>450</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">140</th>
                                    <td> IONIZED CALCIUM</td>
                                    <td>SERUM</td>
                                    <td>450</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">141</th>
                                    <td> LACTIC DEHYDROGENASE (LDH)-SERUM</td>
                                    <td>SERUM</td>
                                    <td>500</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">142</th>
                                    <td> INTACT PARATHYROID HORMONE (IPTH)</td>
                                    <td>SERUM</td>
                                    <td>950</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">143</th>
                                    <td> PROGESTERONE</td>
                                    <td>SERUM</td>
                                    <td>550</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">144</th>
                                    <td> PROLACTIN</td>
                                    <td>SERUM</td>
                                    <td>450</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">145</th>
                                    <td> PROSTATE SPECIFIC ANTIGEN - PSA (FREE)</td>
                                    <td>SERUM</td>
                                    <td>1200</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">146</th>
                                    <td> PROSTATE SPECIFIC ANTIGEN - PSA (TOTAL)</td>
                                    <td>SERUM</td>
                                    <td>450</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">147</th>
                                    <td>PROTEIN ELECTROPHORESIS (SERUM)</td>
                                    <td>SERUM</td>
                                    <td>700</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">148</th>
                                    <td> TESTOSTERONE - FREE</td>
                                    <td>SERUM</td>
                                    <td>1200</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">149</th>
                                    <td>TESTOSTERONE - TOTAL</td>
                                    <td>SERUM</td>
                                    <td>700</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">150</th>
                                    <td> THYROID STIMULATING HORMONE (TSH)</td>
                                    <td>SERUM</td>
                                    <td>250</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">151</th>
                                    <td> TOTAL IGE</td>
                                    <td>SERUM</td>
                                    <td>550</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">152</th>
                                    <td> TROPONIN - I</td>
                                    <td>SERUM</td>
                                    <td>2300</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">153</th>
                                    <td> TOTAL IGE</td>
                                    <td>SERUM</td>
                                    <td>550</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">154</th>
                                    <td> TROPONIN - I</td>
                                    <td>SERUM</td>
                                    <td>2300</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">153</th>
                                    <td>TROPONIN - T</td>
                                    <td>SERUM</td>
                                    <td>2300</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">154</th>
                                    <td> VALPROIC ACID (SODIUM VALPROATE)</td>
                                    <td>SERUM</td>
                                    <td>1200</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">155</th>
                                    <td>VITAMIN -B12</td>
                                    <td>SERUM</td>
                                    <td>550</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">156</th>
                                    <td> VITAMIN D (25-HYDROXY)</td>
                                    <td>SERUM</td>
                                    <td>800</td>
                                  </tr>


                                </tbody>
                                 <!--start-end-->
                                  <!--start-one-->
                                  <tbody id="myTable">
                                    <tr>
                                        <th scope="row">PROFIL'S</th>
                                        
                                      </tr>
                                  <tr>
                                    <th scope="row">157</th>
                                    <td>ALLERGY PANEL FULL</td>
                                    <td>SERUM</td>
                                    <td>10,000</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">158</th>
                                    <td>AMENORRHEA PROFILE (FSH+LH+PRL+TSH)</td>
                                    <td>SERUM</td>
                                    <td>1500</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">159</th>
                                    <td> ANEMIA PROFILE
                                        (CBC+IRON+CAL+UA+FERR+B-12)</td>
                                    <td>S+E</td>
                                    <td>1200</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">160</th>
                                    <td> COAGULATION PROFILE (PT-INR+APTTK)</td>
                                    <td>S.CITRATE</td>
                                    <td>600</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">161</th>
                                    <td>COMPLETE BLOOD COUNT (CBC)</td>
                                    <td>EDTA</td>
                                    <td>350</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">162</th>
                                    <td>  COMPLETE HEAMOGRAM WITH P/S (CBC with
                                        P/S))</td>
                                    <td>EDTA</td>
                                    <td>500</td>
                                  </tr>
                               
                                  <tr>
                                    <th scope="row">163</th>
                                    <td> DUAL MARKER</td>
                                    <td>SERUM</td>
                                    <td>2800</td>
                                  </tr>
                               
                                  <tr>
                                    <th scope="row">164</th>
                                    <td>FEVER PROFILE-A
                                        (CBC+MP+WIDAL+SGOT+SGPT+URINE R/M)</td>
                                    <td>S+E+U</td>
                                    <td>800</td>
                                  </tr>
                               
                                  <tr>
                                    <th scope="row">165</th>
                                    <td>FEVER PROFILE-B</td>
                                    <td>S+E+U</td>
                                    <td>4000</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">166</th>
                                    <td>IRON PROFILE</td>
                                    <td>SERUM</td>
                                    <td>450</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">167</th>
                                    <td>KIDNEY FUNCTION TEST (KFT)</td>
                                    <td>SERUM</td>
                                    <td>450</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">168</th>
                                    <td>LIPID PROFILE</td>
                                    <td>SERUM</td>
                                    <td>450</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">169</th>
                                    <td>LIVER FUNCTION TEST (LFT)</td>
                                    <td>SERUM</td>
                                    <td>500</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">170</th>
                                    <td>QUAD MARKER WITH GRAPH</td>
                                    <td>SERUM</td>
                                    <td>4500</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">171</th>
                                    <td> THYROID PROFILE (T3+T4+TSH)</td>
                                    <td>SERUM</td>
                                    <td>550</td>
                                  </tr>

                                  <tr>
                                    <th scope="row">172</th>
                                    <td> FREE THYROID PROFILE (FT3+FT4+TSH)</td>
                                    <td>SERUM</td>
                                    <td>750</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">173</th>
                                    <td> THYROID PROFILE ADVANCE
                                        (TFT+FT3+FT4+ANTI TPO)</td>
                                    <td>SERUM</td>
                                    <td>1500</td>
                                  </tr>
                                 
                                  <tr>
                                    <th scope="row">174</th>
                                    <td> TORCH PANEL ALL 10</td>
                                    <td>SERUM</td>
                                    <td>2800</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">175</th>
                                    <td> TRIPPLE MARKER WITH GRAPH</td>
                                    <td>SERUM</td>
                                    <td>-</td>
                                  </tr>

                                  <tr>
                                    <th scope="row">176</th>
                                    <td> UWC PROFILE-A (TOTAL-80 TEST) 
                                        
                                    </td>
                                    <td>S+E+F+U</td>
                                    <td>1499</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">177</th>
                                    <td>UWC PROFILE-B (TOTAL-91 TEST)
                                        
                                    </td>
                                    <td>S+E+F+U</td>
                                    <td>2499</td>
                                  </tr>

                                  <tr>
                                    <th scope="row">178</th>
                                    <td>UWC PROFILE-C (TOTAL-98 TEST)
                                        
                                    </td>
                                    <td>S+E+F+U</td>
                                    <td>2999</td>
                                  </tr>
                               
                                  <tr>
                                    <th scope="row">179</th>
                                    <td>UWC PROFILE-D  (TOTAL-108 TEST)
                                        
                                    </td>
                                    <td>S+E+F+U</td>
                                    <td>4999</td>
                                  </tr>
                               
                               
                                 
                                
                                </tbody>
                                 <!--start-end-->
                              </table>
                        </div>
                    </div>




                </div>


            </div>
        </div>
    </div>
    
</section>