import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import{ HttpClient } from '@angular/common/http';
import { Title, Meta } from '@angular/platform-browser';
@Component({
  selector: 'app-home',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class  AboutUsComponent implements OnInit {

  title='Get In Touch !';
  alert: boolean=false;
  url='https://uniquewellnesscare.com/backend/saveDetails.php';
  contactForm: FormGroup;
  constructor(private httpClient: HttpClient, private meta: Meta, private titleService: Title)  {
    
  }
  ngOnInit(){
   // Update title dynamically
   this.titleService.setTitle('About Unique Wellness Care | Leading Healthcare Provider ');

   // Update meta tags dynamically
   this.meta.updateTag({ name: 'description', content: 'Learn about our commitment to high-quality healthcare since 2014. Dedicated to accuracy, compassion, and innovation. Your health, our priority.' });

   // Add canonical link dynamically
   this.meta.addTag({ rel: 'canonical', href: '/about-us' });

    this.contactForm= new FormGroup({
      title: new FormControl(this.title),
      name: new FormControl('',[Validators.required]),
      email: new FormControl('',[Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]),
      phone_number: new FormControl('',[Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]),
      message: new FormControl('')
    })
  }
    get name(){return this.contactForm.get('name')}
    get email(){return this.contactForm.get('email')}
    get phone_number(){return this.contactForm.get('phone_number')}
  
  onSubmit(){
    if(this.contactForm.invalid){
      return;
    }
    this.httpClient.post(this.url,this.contactForm.value).subscribe((data)=>{
      if(data['error']===0){
       this.alert = true;
       setTimeout(() => {
       this.alert = false;
        }, 5000)
      }
    },
    error=>{
      // DO Something when error comes
      // this.alert=true;
    })
    this.contactForm.reset();
  }


}
