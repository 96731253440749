<section class="coming-soon-area">
    <div class="container-fluid p-0">
        <div class="row m-0">
            <div class="col-lg-6 col-md-12 d-flex align-items-center justify-content-center">
            
                <div class="logo">
                    <a routerLink="/"><img src="assets/img/logo.png" class="uwc-logo uwc-logo mx-auto d-block" alt="logo"></a>
                    <p class="downlord-p text-center">Due to covid 19 Pandemic.<br>Your saftey is our first Priority!</p>
                </div> 
             
              
            </div>
            <div class="col-lg-6 col-md-12 p-0">
                <div class="coming-soon-content">
                    <div class="d-table">
                        <div class="d-table-cell">
                            <!-- <div class="logo">
                                <img src="assets/img/logo.png" alt="logo">
                            </div>-->
                            <h3>Download e Report!</h3>
                            
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="User Name" required>
                                </div>
                                <div class="form-group">
                                    <input type="password" class="form-control" placeholder="Password" required>
                                </div>
                                <button type="submit" class="btn btn-primary">Get Report</button>
                                <!-- <p>And don’t worry, we hate spam too! You can unsubcribe at anytime.</p> -->
                           
                        </div>
                    </div>
                </div>
            </div>

            
        </div>
       
    </div>
    
</section>