<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>TERMS & CONDITIONS</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>TERMS & CONDITIONS</li>
            </ul>
        </div>
    </div>
</div>
<section class="shop-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                
               <p> Welcome to <a href="https:www.uniquewellnesscare.com" class="termsandcondition" target="_blank">uniquewellnesscare.com</a> website. The content of this website pertains to <a href="https:www.uniquewellnesscare.com" class="termsandcondition" target="_blank">uniquewellnesscare.com</a> and is the property of Expedient Unique Wellness Care Private Limited having its registered office at, Plot No.19,Street No.5/12, Guru Nanak Dev Colony, Bhalswa Dairy, New Delhi, Delhi 110042, 
                <a href="https:www.uniquewellnesscare.com" class="termsandcondition" target="_blank">uniquewellnesscare.com</a> is Delhi’s largest test &#64;home service and assures quality collection and testing across its network of labs and hundreds of full-time phlebotomists. </p> 
                <a href="mailto:info@uniquewellnesscare.com"><i class="fas fa-envelope"></i> info&#64;uniquewellnesscare.com</a>
                <br> 
                <i class="fa fa-thumbs-up" aria-hidden="true"></i>
By submitting the form above, you are allowing <a href="https:www.uniquewellnesscare.com" class="termsandcondition" target="_blank">Unique Wellness Care Lab</a> Health Advisors to call you back at your convenient time.
<br><br> 
<i class="fa fa-thumbs-up" aria-hidden="true"></i>
Our Health advisor and Medical team will be involved to guide you in the complete cycle of the testing process and will facilitate the diagnosis process for prevention of any disease.
<br><br> 
<i class="fa fa-thumbs-up" aria-hidden="true"></i>
Your health vitals and history will be taken for pre-diagnosis and for suggesting the relevant tests through Health Karma or you can also share your prescription with our medical team.
<br><br> 
<i class="fa fa-thumbs-up" aria-hidden="true"></i>
Once you confirm your booking, a trained Phlebotomists (Sample Collector) will be assigned to visit you on your chosen time and pickup address and for collection of sample. Phlebotomist may also take other vitals if suggested in the pre diagnosis.
<br><br> 
<i class="fa fa-thumbs-up" aria-hidden="true"></i>
Your sample will then be transported to the nearest collection hub maintaining the integrity of the sample and will be centrifuged before sending to the lab for processing.
<br><br> 
<i class="fa fa-thumbs-up" aria-hidden="true"></i>
Once the results are processed in the <a href="https:www.uniquewellnesscare.com" class="termsandcondition" target="_blank">Unique Wellness Care Lab</a>, medical team at <a href="https:www.uniquewellnesscare.com" class="termsandcondition" target="_blank">Unique Wellness Care </a> will verify the results and a smart report including historical trends and vital’s information submitted by you along with simple interpretations will be shared with you.
<br><br> 
<i class="fa fa-thumbs-up" aria-hidden="true"></i>
You will also be offered a post report counselling by our Doctors as a part of the healthcare service offered to you.
<br><br> 
<i class="fa fa-thumbs-up" aria-hidden="true"></i>
 <a href="https:www.uniquewellnesscare.com" class="termsandcondition" target="_blank">Unique Wellness Care Lab</a> Lab offers 24*7 customer support to resolve any of your concern during and after the entire diagnosis process.
<br><br> 
<i class="fa fa-thumbs-up" aria-hidden="true"></i>
Dear Customer, we are pleased on your signup with <a href="https:www.uniquewellnesscare.com" class="termsandcondition" target="_blank">Unique Wellness Care Lab</a> today. By entering your phone number you agree that we may send you text notifications, text marketing offers and health checkup reminders. By signing up you provide your consent to receive communication from Unique Wellness Care Lab.
               
















            </div>
        </div>
    </div>
</section>