import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './components/pages/home/home.component';
import { AboutUsComponent } from './components/pages/about-us/about-us.component';


import { ErrorComponent } from './components/pages/error/error.component';

import { ComingSoonComponent } from './components/pages/coming-soon/coming-soon.component';


import { ContactComponent } from './components/pages/contact/contact.component';
import { AProfileComponent } from './components/pages/a-profile/a-profile.component';
import { BProfileComponent } from './components/pages/b-profile/b-profile.component';
import { CProfileComponent } from './components/pages/c-profile/c-profile.component';
import { DProfileComponent } from './components/pages/d-profile/d-profile.component';
import { BookNowComponent } from './components/pages/book-now/book-now.component';
import { ForADSAComponent } from './components/pages/for-a-dsa/for-a-dsa.component';
import { BusinessPartnerComponent } from './components/pages/business-partner/business-partner.component';
import { LookingForCorporateTieUpComponent } from './components/pages/looking-for-corporate-tie-up/looking-for-corporate-tie-up.component';
import { AnyOtherQueryComponent } from './components/pages/any-other-query/any-other-query.component';
import { FeedBackComponent } from './components/pages/feed-back/feed-back.component';
import { LoginComponent } from './components/pages/login/login.component';
import { CutomerLoginComponent } from './components/cutomer-login/cutomer-login.component';

import { TestListComponent } from './test-list/test-list.component';
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';





const routes: Routes = [
    { path: '', redirectTo: '/', pathMatch: 'full' },
    {path: '', component: HomeComponent},
   
    
    {path: 'about-us', component: AboutUsComponent},
    {path: 'error', component: ErrorComponent},
  
    {path: 'coming-soon', component: ComingSoonComponent},
   

    {path: 'contact', component: ContactComponent},
    {path: 'a-profile', component: AProfileComponent},
    {path: 'b-profile', component: BProfileComponent},
    {path: 'c-profile', component: CProfileComponent},
    {path: 'd-profile', component: DProfileComponent},
    {path: 'book-now', component: BookNowComponent},
    {path: 'for-a-dsa', component: ForADSAComponent},
    {path: 'business-partner', component: BusinessPartnerComponent},
    {path: 'looking-for-corporate-tie-up', component: LookingForCorporateTieUpComponent},
    {path: 'any-other-query', component: AnyOtherQueryComponent},
    {path: 'feed-back', component: FeedBackComponent},
    {path: 'login', component: LoginComponent},
    {path: 'customer-login', component: CutomerLoginComponent},

    {path: 'test-list', component: TestListComponent},
    {path: 'terms-conditions', component: TermsConditionsComponent},
    {path: 'privacy-policy', component: PrivacyPolicyComponent},
 
    

    
   
    
    
    {path: '**', component: ErrorComponent} // This line will remain down from the whole component list
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }